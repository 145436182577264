import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/6.png";
import { Divider } from "primereact/divider";

function AcupunctureCardsImg() {
  return (
    <div className="AcupunctureCardsImg" style={{paddingTop:'60px'}}>
      <div className="card w-full text-center"
          style={{
            padding: "0 30px",
            color: "#e75480",
          }}>
          <Divider align="center"><h2 className="p-3">Acupuntura</h2></Divider>
      </div>
          <img className="w-full"
            src={Img}
            alt="cardsimg"
          />
    </div>
  );
}

export default AcupunctureCardsImg;
