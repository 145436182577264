import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/z2.jpg";
import Img1 from "./img/z3.jpg";
import Img2 from "./img/z4.jpg";

function FUESapphireCardsImgBody() {
  return (
    <div className="FUESapphireCardsImgBody">
      <div className="card w-full text-center"
        style={{ padding: "30px" }}>
        <div className="grid mx-0">
          <div className="col-12 md:col-4">
            <h2 className="p-3"
              style={{ color: "#e75480" }}>
              Recolección folículos capilares
            </h2>
            <p
              style={{ color: "gray" }}>
              <strong>Con el método FUE :</strong> los folículos capilares se recolectan en la
              zona posterior de la cabeza, conocida como zona segura, mediante
              el uso de micropunzones de bajas revoluciones y con el uso de
              anestesia local.
            </p>
            <p className=" md:h-20rem"
              style={{ color: "gray" }}>
              Estos micropunzones pueden extraer injertos multifoliculares
              (con dos o más folículos) sin dañarlos. Cada cabello nace, crece
              y se desarrolla independientemente del resto, dentro de una
              bolsa o bulbo llamado folículo piloso.
            </p>
            <img className="w-full"
              style={{ padding: "30px" }}
              src={Img}
              alt="cardsimg"
            />
          </div>
          <div className="col-12 md:col-4">
            <h2 className="p-3"
              style={{ color: "#e75480" }}>
              Apertura de canales para injertos
            </h2>
            <p
              style={{ color: "gray", textAlign: "center" }}
            >
              <strong> Técnica percutanea del Zafiro :</strong> El método FUE con Zafiro se trata de una técnica especial
              empleada en reducido número de clínicas del mundo,
              principalmente en Norteamérica. En vez de trasplantarse en
              hendiduras, los folículos se trasplantan en canales
              tridimensionales creados con instrumental altamente
              especializado utilizando auténticas agujas de zafiro.
            </p>
            <p
              style={{ color: "gray", textAlign: "center" }}
            >
              Con el método FUE con Zafiro el riesgo de causar daño es mínimo
              ya que los canales abiertos tienen las mismas medidas que los
              folículos capilares y el periodo de recuperación es el doble de
              rápido que empleando otras técnicas. La zona de trasplante se
              recupera en tan solo 2 – 3 días y no deja cicatriz. Estos finos
              canales pueden introducirse entre folículos capilares, evitando
              así cualquier tipo de daño. La cantidad de líquido aplicado con
              la técnica percutánea del Zafiro es también menor, por lo que la
              hinchazón tras la intervención también se reduce.
            </p>
            <img className="w-full"
              src={Img1}
              alt="cardsimg"
              style={{ padding: "30px" }} />
          </div>
          <div className="col-12 md:col-4">
            <h2 className="p-3"
              style={{ color: "#e75480" }}>
              Ventajas método FUE
            </h2>
            <p
              style={{ color: "gray", textAlign: "center" }}
            >
              <strong>Una de las mayores ventajas del método FUE :</strong>
              es que ofrece un aspecto natural. La clave para obtener este resultado natural es
              que los folículos capilares se trasplanten en el ángulo correcto
              lo que imposibilita que los injertos cambien su orientación tras
              la operación.
            </p>
            <p
              className=" md:h-17rem"
              style={{ color: "gray", textAlign: "center" }}
            >
              Este resultado no se puede obtener mediante la técnica
              tradicional ya que las hendiduras son mayores que los folículos
              capilares y estos no pueden ajustarse en un ángulo natural.
            </p>
            <img className="w-full"
              src={Img2}
              alt="cardsimg"
              style={{ padding: "30px" }} />
          </div>
        </div>

        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          <strong>*ADVERTENCIA</strong>: Cada persona es única. Esto significa que la
          duración del tratamiento, la planificación y los resultados pueden
          ser diferentes para usted. La información que ve aquí es el
          promedio resultante de una amplia gama de muestras. Puede
          contactarnos para obtener información mas detallada a través del
          teléfono 649 633 448.
        </p>
      </div>
    </div>
  );
}

export default FUESapphireCardsImgBody;
