import React from "react";
import { Menubar } from "primereact/menubar";
import logo from "../img/logo-estethica@2x.png";

export default function Navbar() {
  const items = [
    {
      label: "Inicio",
      url: "/",
    },
    {
      label: "Transplante capilar",
      items: [
        {
          label: "FUE con zafiro",
          url: "/transplante-capilar/fue-con-zafiro",
        },
        {
          label: "DHI Plus Gold",
          url: "/transplante-capilar/dhi-plus-gold",
        }
      ],
    },
    {
      label: "Tratamientos",
      items: [
        {
          label: "Rinoplastia",
          url: "/tratamientos/rinoplastia",
        },
        {
          label: "Estética mamaria",
          url: "/tratamientos/estetica-mamaria",
        },
        {
          label: "Six Pack",
          url: "/tratamientos/six-pack",
        },
        {
          label: "Láser Lipolysis",
          url: "/tratamientos/laser-lipolysis-plus",
        },
        {
          label: "Otros tratamientos",
          items: [
            {
              label: "Clínica de Restauración y Salud del Cabello",
              url: "/tratamientos/restauracion-clinica",
            },
            {
              label: "Dermatología y estética médica",
              url: "/tratamientos/dermatologia",
            },
            {
              label: "Clínica de salud oral y dental",
              url: "/tratamientos/clinica-salud-oral",
            },
            {
              label: "Acupuntura",
              url: "/tratamientos/acupuntura",
            },
            {
              label: "Venas varicosas",
              url: "/tratamientos/venas-varicosas",
            },
          ],
        },
      ],
    },
    {
      label: "Tarifas",
      url: "/tarifas",
    },
    {
      label: "Antes / Después",
      url: "/antes-despues",
    },
    {
      label: "¿Quiénes somos?",
      url: "/quienes-somos",
    },
    {
      label: "Equipo médico",
      url: "/equipo-medico",
    },
  ];
  const start = <img alt="logo"
   src={logo}
    width="180px"
     height="auto"
     className="px-1"
      onClick={() => window.location.href = '/'}></img>;

  return (
    <Menubar
      className='w-full fixed z-5 shadow-8 '
      model={items}
      end={start}
      style={{
        background: "rgb(98, 85, 89)",
        background: "linear-gradient(249deg, rgba(98, 85, 89, 1) 0 %, rgba(221, 221, 221, 1) 79 %)",
        borderRadius: '0 0 12px 12px',
        height: 70
      }} />
  );
}
