import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/1.png";
import { Divider } from "primereact/divider";
function BreastAestheticsCardsImg() {
  return (
    <div className="BreastAestheticsCardsImg" style={{ paddingTop: '60px' }}>
      <div className="card">
        <div className="w-full text-center"
          style={{
            padding: "30px",
            color: "#e75480",
          }}
        >
          <h2
            className="px-3">
            Aumento de pecho en Turquía</h2>
          <Divider
            align="center">
            <h3 className="px-3">
              Estética mamaria</h3>
          </Divider>
          <p
            className="p-3"
            style={{ color: "gray" }}>
            La operación de aumento de pecho es una de las más realizadas y una
            experiencia extremadamente satisfactoria para muchas mujeres. Se
            implementa con técnicas innovadoras y modernas que pueden aplicarse
            fácilmente a cualquier edad y no dejan rastro.
          </p>
          <p
            className="px-3"
            style={{ color: "gray" }}>
            Los senos pequeños se pueden agrandar y rellenar con una operación
            de aumento de pecho para estéticamente adquirir un aspecto más
            agradable y bello.
          </p>
        </div>
        <img
          className="w-full"
          src={Img}
          alt="cardsimg"
        />
      </div>
    </div>
  );
}

export default BreastAestheticsCardsImg;
