import React from "react";
import Navbar from "../Homepage/NavBar";
import PageFooter from "../Homepage/PageFooter";
import { Divider } from "primereact/divider";
import estethica from "../img/logo-estethica@2x.png";
export default function PrivacyPolicies() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex align-items-center justify-content-center w-full "
        style={{ paddingTop: '60px', color: "gray" }}>
        <div className="card w-full text-center">
          <div
            style={{
              color: "#e75480",
              padding: '0 30px'
            }}
          >
            <Divider align="center">
              <img src={estethica} className=" p-3" alt="estethica.icon" style={{ width: "17rem" }} />
            </Divider>
            <h2 className="px-3"
              style={{ color: "#E75480" }}>
              Política de privacidad de Estethica
            </h2>

            <p className="px-3"
              style={{ color: "gray" }}>
              <strong> ESTETHICA ESPAÑA</strong> informa a los usuarios del sitio web sobre su
              política respecto del tratamiento y protección de los datos de
              carácter personal de los usuarios y clientes que puedan ser
              recabados por la navegación o contratación de servicios a través
              de su sitio web. En este sentido, ESTETHICA ESPAÑA garantiza el
              cumplimiento de la normativa vigente en materia de protección de
              datos personales, reflejada en la Ley Orgánica 15/1999 de 13 de
              diciembre, de Protección de Datos de Carácter Personal y en el
              Real Decreto 1720/2007, de 21 diciembre, por el que se aprueba el
              Reglamento de Desarrollo de la LOPD. El uso de esta web implica la
              aceptación de esta política de privacidad.
            </p>
            <h3 className="px-3"
              style={{ color: "#08c" }}>
              1. Recogida, finalidad y tratamientos de datos
            </h3>
            <p className="px-3" style={{ color: "gray" }}>
              <strong> ESTETHICA ESPAÑA</strong> tiene el deber de informar a los usuarios de su
              sitio web acerca de la recogida de datos de carácter personal que
              pueden llevarse a cabo, bien sea mediante el envío de correo
              electrónico o al cumplimentar los formularios incluidos en el
              sitio web. En este sentido, ESTETHICA ESPAÑA será considerada como
              responsable de los datos recabados mediante los medios
              anteriormente descritos.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              A su vez <strong>ESTETHICA ESPAÑA</strong> informa a los usuarios de que la
              finalidad del tratamiento de los datos recabados contempla: La
              atención de solicitudes realizadas por los usuarios, la inclusión
              en la agenda de contactos, la prestación de servicios y la gestión
              de la relación comercial.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Las operaciones, gestiones y procedimientos técnicos que se
              realicen de forma automatiza da o no automatizada y que
              posibiliten la recogida, el almacenamiento, la modificación, la
              transferencia y otras acciones sobre datos de carácter personal,
              tienen la consideración de tratamiento de datos personales.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Todos los datos personales, que sean recogidos a través del sitio
              web de ESTETHICA ESPAÑA, y por tanto tenga la consideración de
              tratamiento de datos de carácter personal, serán incorporados en
              los ficheros declarados ante la Agencia Española de Protección de
              Datos por ESTETHICA ESPAÑA.
            </p>
            <h3 className="px-3"
              style={{ color: "#08c" }}>
              2. Comunicación de información a terceros
            </h3>
            <p className="px-3" style={{ color: "gray" }}>
              <strong>ESTETHICA ESPAÑA</strong> informa a los usuarios de que sus datos
              personales no serán cedidos a terceras organizaciones, con la
              salvedad de que dicha cesión de datos este amparada en una
              obligación legal o cuando la prestación de un servicio implique la
              necesidad de una relación contractual con un encargado de
              tratamiento. En este último caso, solo se llevará a cabo la cesión
              de datos al tercero cuando ESTETHICA ESPAÑA disponga del
              consentimiento expreso del usuario.
            </p>
            <h3 className="px-3"
              style={{ color: "#08c" }}>
              3. Derechos de los usuarios
            </h3>
            <p className="px-3" style={{ color: "gray" }}>
              La Ley Orgánica 15/1999, de 13 de diciembre, de Protección de
              Datos de Carácter Personal concede a los interesados la
              posibilidad de ejercer una serie de derechos relacionados con el
              tratamiento de sus datos personales.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              En tanto en cuanto los datos del usuario son objeto de tratamiento
              por parte de Sofás RIA S.L.. Los usuarios podrán ejercer los
              derechos de acceso, rectificación, cancelación y oposición de
              acuerdo con lo previsto en la normativa legal vigente en materia
              de protección de datos personales. Para hacer uso del ejercicio de
              estos derechos, el usuario deberá dirigirse mediante comunicación
              escrita, aportando documentación que acredite su identidad (DNI o
              pasaporte), a la siguiente dirección:
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              <strong> ESTETHICA ESPAÑA</strong> en la calle Dolores Marqués, 29, p-05, 14.
              Valencia. o la dirección que sea sustituida en el Registro General
              de Protección de Datos. Dicha comunicación deberá reflejar la
              siguiente información: Nombre y apellidos del usuario, la petición
              de solicitud, el domicilio y los datos acreditativos.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              El ejercicio de derechos deberá ser realizado por el propio
              usuario. No obstante, podrán ser ejecutados por una persona
              autorizada como representante legal del autorizado. En tal caso,
              se deberá aportar la documentación que acredite esta
              representación del interesado.
            </p>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
}
