import React from "react";
import Navbar from "../../../../Homepage/NavBar";
import VaricoseVeinsCardsImg from "./VaricoseVeinsCardsImg";
import VaricoseVeinsCardsBody from "./VaricoseVeinsCardsBody";
import PageFooter from "../../../../Homepage/PageFooter";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../../../App.css";

function VaricoseVeins() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="VaricoseVeins">
      <progress max={100} value={0}></progress>
      <Navbar />
      <VaricoseVeinsCardsImg style={{ paddingTop: '40px' }} />
      <VaricoseVeinsCardsBody />
      <PageFooter />
    </div>
  );
}

export default VaricoseVeins;