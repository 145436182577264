import React from "react";
import { Card } from "primereact/card";

function AcupunctureCardsBody() {
  return (
    <div className="AcupunctureCardsBody">
      <div className="card text-center w-full"
          style={{
            padding: "30px",
          }}
        >
          <div
            className="accordion accordion-flush m-0 p-5"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  style={{ color: "#e75480" }}
                >
                  Acupuntura
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    De acuerdo con la filosofía de la acupuntura, hay 14
                    meridianos en el cuerpo humano, 12 pares y 2 meridianos.
                    Estos meridianos tienen 360 puntos en ellos. El tratamiento
                    de acupuntura se realiza a través de estos puntos.
                  </p>
                  <p style={{ color: "gray" }}>
                    La acupuntura se usa en un amplio rango de salud como
                    trastornos nerviosos, depresión, ataques de pánico,
                    estreñimiento, estrés, irregularidades menstruales,
                    epilepsia, dolor de espalda y cuello, náuseas del embarazo y
                    vómitos, tratamiento de fecundación in vitro, la migraña y
                    el dolor de cabeza, trastornos del asma, problemas
                    digestivos, bronquitis…
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                  style={{ color: "#e75480" }}
                >
                  Tratamiento de acupuntura
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    Con el tratamiento de acupuntura, las personas están
                    preparadas para un cuerpo más saludable. La terapia de
                    acupuntura también ayuda a las personas a:
                  </p>
                  <p style={{ color: "gray" }}>Disminuir el apetito</p>
                  <p style={{ color: "gray" }}>Regular la tasa metabólica</p>
                  <p style={{ color: "gray" }}>
                    Eliminar cualquier efecto secundario
                  </p>
                  <p style={{ color: "gray" }}>Reducir el estrés diario</p>
                  <p style={{ color: "gray" }}>Prevenir la fatiga</p>
                  <p style={{ color: "gray" }}>
                    Como resultado de estas sustancias, las personas pueden
                    tener una vida más cómoda, y el metabolismo funciona mejor
                    regularmente.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default AcupunctureCardsBody;
