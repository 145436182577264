import React from "react";
import { Card } from "primereact/card";

function RestorationClinicCardsBody() {
  return (
    <div className="RestorationClinicCardsBody">
      <div className="card text-center w-full" style={{
        padding: "30px",
      }}>
        <div
          className="accordion accordion-flush p-3"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                style={{ color: "#e75480" }}
              >
                Alisado sin afeitar indoloro
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Los centros de trasplante de cabello se pueden aplicar con
                  técnicas indoloras y sin afeitar aplicadas en clínicas en
                  todo el mundo. El cabello sin afeitar y sin afeitar está en
                  el nivel más bajo de plantación, sangrado e hinchazón. Las
                  técnicas de trasplante de cabello sin blindaje que limitan
                  la cantidad de cabello agregado se pueden combinar con la
                  técnica de percusión Sapphire, que es la pionera en nuestro
                  país, para aumentar el número de injertos y proporcionar una
                  apariencia más frecuente.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                style={{ color: "#e75480" }}
              >
                Técnica del zafiro
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  El Grupo Estethica realiza trasplantes capilares con las
                  técnicas más modernas e innovadoras del mundo. En nuestros
                  centros realizamos trasplantes de cabello, bigote y cejas
                  empleando la tecnología más puntera del mercado. La técnica
                  percutánea del zafiro es la práctica más innovadora en este
                  campo, del que fuimos pioneros en nuestro país.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
                style={{ color: "#e75480" }}
              >
                Mesoterapia capilar
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Viene a la vanguardia de muchas aplicaciones clínicas
                  conocidas y aplicadas. De hecho, se requiere termoterapia
                  capilar y un masaje especial en la cabeza. Al igual que la
                  termoterapia capilar, el cabello se ablanda con la ayuda de
                  un vapor ozonizado y se prepara el mesotelioma. En este
                  sentido, el cuero cabelludo también está sufriendo de la
                  debilidad de sentir dolor. Las observaciones clínicas han
                  demostrado que la mesoterapia capilar realizada sin
                  termoterapia capilar es más dolorosa.
                </p>
                <p style={{ color: "gray" }}>
                  En la estructura del cabello después de vitamina
                  termoterapia, minerales, agentes queratolíticos, minoxidil,
                  aumento de la circulación y para preparar una mezcla
                  especial de los anestésicos locales y ‘mesoterapia pistola
                  pelo’ se inserta. Con una computadora conectada a esta
                  pistola, se decide la cantidad de medicamento que se
                  aplicará al cuero cabelludo cuando se presione cada gatillo.
                  El médico también puede controlar la liberación de la droga
                  mientras la aguja está adentro, afuera o adentro. Después de
                  realizar las configuraciones, el medicamento se dispensa a
                  intervalos regulares a través de la pistola con todo el
                  cabello.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
                style={{ color: "#e75480" }}
              >
                Plantación de barba y bigote
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  La barba tiene una influencia importante en la formación de
                  la identidad en los hombres, la apariencia exterior del
                  estilo de vida y la psicología. Plantación de barba; en caso
                  de que no haya barba o barba en el área donde hay heridas,
                  quemaduras, problemas de dilución causados ​​por problemas
                  tales como traer soluciones. El cabello enraizado en el
                  cultivo de la barba se transfiere al área requerida. Cuando
                  las diluciones después de varias causas se eliminan de la
                  barba mediante cultivo de barba, se realizan cambios serios
                  en los casos en que no hay barba. En el método FUE, el
                  cabello se toma uno por uno, y se evitan los vestigios en
                  este lado. En octubre, es natural ser leal al ángulo al que
                  debería ir la barba.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
                style={{ color: "#e75480" }}
              >
                Peluquería en mujeres
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  La pérdida de cabello es un problema de salud que las
                  mujeres a menudo encuentran. Para detener la caída del
                  cabello causada por diferentes motivos, se aplican todo tipo
                  de productos cosméticos. Si la pérdida de cabello continúa,
                  la parte superior de la cabeza se abre.
                </p>
                <p style={{ color: "gray" }}>
                  Si el resultado de la caída del cabello es la parte superior
                  de la cabeza, es hora de sembrar el cabello, ya que los
                  productos cosméticos que se usan después de verter el
                  cabello no eliminan el cabello. La planta capilar, que se
                  piensa que está dirigida solo a hombres, se puede aplicar
                  con éxito a las mujeres. El proceso continúa con el curso
                  habitual de trasplante de cabello.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default RestorationClinicCardsBody;
