import React from "react";
import { Image } from "primereact/image";
import ImageInicio from "../img/Inicio.png";

export default function Photo() {
  return (
    <div className="Photo" style={{paddingTop:'40px'}}>
      <Image
        src={ImageInicio}
        alt="Portada"
        width="100%"
        padding="30px"
        imageClassName="py-3"
      />
    </div>
  );
}
