import React from "react";
import Navbar from "../Homepage/NavBar";
import { Divider } from "primereact/divider";
import PageFooter from "../Homepage/PageFooter";
import estethica from "../img/logo-estethica@2x.png";
export default function CookiesPolicies() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex align-items-center justify-content-center w-full "
        style={{ paddingTop: '60px', color: "gray" }}>
        <div className="card w-full text-center">
          <div
            style={{
              color: "#e75480",
              padding: '0 30px'
            }}
          >
            <Divider align="center">
              <img src={estethica} className=" p-3" style={{ width: "17rem" }} alt="estethica.icon" />
            </Divider>
            <h2 className="p-3"
              style={{ color: "#E75480" }}>
              Política de cookies
            </h2>
            <p className="px-3" style={{ color: "gray" }}>
              Siguiendo las directrices de la <strong>
                Agencia Española de Protección de
                Datos</strong> procedemos a detallar el uso de cookies que hace esta web
              con el fin de informarle con la máxima exactitud posible.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Este sitio web utiliza las siguientes cookies propias:
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Cookies de sesión, para garantizar que los usuarios que escriban
              comentarios en el blog sean humanos y no aplicaciones
              automatizadas. De esta forma se combate el spam.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Este sitio web utiliza las siguientes cookies de terceros:
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Google Analytics: Almacena cookies para poder elaborar
              estadísticas sobre el tráfico y volumen de visitas de esta web. Al
              utilizar este sitio web está consintiendo el tratamiento de
              información acerca de usted por Google. Por tanto, el ejercicio de
              cualquier derecho en este sentido deberá hacerlo comunicando
              directamente con Google.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Redes sociales: Cada red social utiliza sus propias cookies para
              que usted pueda pinchar en botones del tipo Me gusta o Compartir.
            </p>
            <h3 className="px-3"
              style={{ color: "#08c" }}>
              Desactivación o eliminación de cookies
            </h3>
            <p className="px-3" style={{ color: "gray" }}>
              En cualquier momento podrá ejercer su derecho de desactivación o
              eliminación de cookies de este sitio web. Estas acciones se
              realizan de forma diferente en función del navegador que esté
              usando.
            </p>
            <h3 className="px-3"
              style={{ color: "#08c" }}>
              Notas adicionales
            </h3>
            <p className="px-3" style={{ color: "gray" }}>
              Ni esta web ni sus representantes legales se hacen responsables ni
              del contenido ni de la veracidad de las políticas de privacidad
              que puedan tener los terceros mencionados en esta política de
              cookies.
            </p>

            <p className="px-3" style={{ color: "gray" }}>
              Los navegadores web son las herramientas encargadas de almacenar
              las cookies y desde este lugar debe efectuar su derecho a
              eliminación o desactivación de las mismas. Ni esta web ni sus
              representantes legales pueden garantizar la correcta o incorrecta
              manipulación de las cookies por parte de los mencionados
              navegadores
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              En algunos casos es necesario instalar cookies para que el
              navegador no olvide su decisión de no aceptación de las mismas.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              En el caso de las cookies de Google Analytics, esta empresa
              almacena las cookies en servidores ubicados en Estados Unidos y se
              compromete a no compartirla con terceros, excepto en los casos en
              los que sea necesario para el funcionamiento del sistema o cuando
              la ley obligue a tal efecto. Según Google no guarda su dirección
              IP. Google Inc. es una compañía adherida al Acuerdo de Puerto
              Seguro que garantiza que todos los datos transferidos serán
              tratados con un nivel de protección acorde a la normativa europea.
              Puede consultar información detallada a este respecto en este
              enlace. Si desea información sobre el uso que Google da a las
              cookies le adjuntamos este otro enlace.
            </p>
            <p className="px-3" style={{ color: "gray" }}>
              Para cualquier duda o consulta acerca de esta política de cookies
              no dude en comunicarse con nosotros a través de la sección de
              contacto.
            </p>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
}
