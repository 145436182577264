import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ScrollTop } from 'primereact/scrolltop';
import "./App.css";
import Home from "./component/Home";
import FUESapphire from "./component/AuxuliaryPages/HairTransplant/FUESappire/FUESapphire";
import DHIPlusGold from "./component/AuxuliaryPages/HairTransplant/DHIPlusGold/DHIPlusGold";
import Rhinoplasty from "./component/AuxuliaryPages/Treatments/Rhinoplasty/Rhinoplasty";
import BreastAesthetics from "./component/AuxuliaryPages/Treatments/BreastAesthetics/BreastAesthetics";
import SixPack from "./component/AuxuliaryPages/Treatments/SixPack/SixPack";
import LaserLipolysisPlus from "./component/AuxuliaryPages/Treatments/LaserLipolysisPlus/LaserLipolysisPlus";
import RestorationClinic from "./component/AuxuliaryPages/Treatments/OtherTreatments/RestorationClinic/RestorationClinic";
import Dermatology from "./component/AuxuliaryPages/Treatments/OtherTreatments/Dermatology/Dermatology";
import OralHealth from "./component/AuxuliaryPages/Treatments/OtherTreatments/OralHealth/OralHealth";
import Acupuncture from "./component/AuxuliaryPages/Treatments/OtherTreatments/Acupuncture/Acupuncture";
import VaricoseVeins from "./component/AuxuliaryPages/Treatments/OtherTreatments/VaricoseVeins/VaricoseVeins";
import Tarifas from "./component/AuxuliaryPages/Rates/Tarifas";
import BeforeAfter from "./component/AuxuliaryPages/BeforeAfter/BeforeAfter";
import AboutUs from "./component/AuxuliaryPages/AboutUs/AboutUs";
import MedicalEquipment from "./component/AuxuliaryPages/MedicalEquipment/MedicalEquipment";
import PageNotFound404 from "./component/PageNotFound404/PageNotFound404";
import PrivacyPolicy from "./component/PolicyPrivacity/PrivacyPolicies";
import CookiesPolicy from "./component/PolicyPrivacity/CookiesPolicies";
import Map from "./component/PolicyPrivacity/Map";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path='/transplante-capilar/fue-con-zafiro' element={<FUESapphire />} />
        <Route exact path='/transplante-capilar/dhi-plus-gold' element={<DHIPlusGold />} />
        <Route exact path='/tratamientos/rinoplastia' element={<Rhinoplasty />} />
        <Route exact path='/tratamientos/estetica-mamaria' element={<BreastAesthetics />} />
        <Route exact path='/tratamientos/six-pack' element={<SixPack />} />
        <Route exact path='/tratamientos/laser-lipolysis-plus' element={<LaserLipolysisPlus />} />
        <Route exact path='/tratamientos/restauracion-clinica' element={<RestorationClinic />} />
        <Route exact path='/tratamientos/dermatologia' element={<Dermatology />} />
        <Route exact path='/tratamientos/clinica-salud-oral' element={<OralHealth />} />
        <Route exact path='/tratamientos/acupuntura' element={<Acupuncture />} />
        <Route exact path='/tratamientos/venas-varicosas' element={<VaricoseVeins />} />
        <Route exact path='/tarifas' element={<Tarifas />} />
        <Route exact path='/antes-despues' element={<BeforeAfter />} />
        <Route exact path='/quienes-somos' element={<AboutUs />} />
        <Route exact path='/equipo-medico' element={<MedicalEquipment />} />
        <Route exact path="/politica-privacidad" element={<PrivacyPolicy />} />
        <Route exact path="/politica-cookies" element={<CookiesPolicy />} />
        <Route exact path="/mapa-sitio" element={<Map />} />
        <Route path='*' element={<PageNotFound404 />} />
      </Routes>
      <ScrollTop />
    </Router>
  );
}

export default App;
