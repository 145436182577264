import React from "react";
import { Card } from "primereact/card";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/z-1.png";
import DiamondIcon from "@mui/icons-material/Diamond";
import { Divider } from "primereact/divider";

function FUESapphireCards() {
  return (
    <div className="FUESapphireCards" style={{ paddingTop: '60px' }}>
      <div className="card w-full text-center">
        <div
          style={{
            color: "#e75480",
            padding:'30px'
          }}
        >
          <Divider align="center">
            <h2 className="px-3">
              Trasplante capilar método FUE con Zafiro
              </h2>
              </Divider>
          <p className="px-3" 
          style={{ color: "gray" }}>
            El Grupo Estethica realiza trasplantes capilares en Turquía con las
            técnicas más modernas e innovadoras del mundo. En nuestros centros
            realizamos trasplantes de cabello, bigote y cejas empleando la
            tecnología más puntera del mercado.
          </p>
          <p className="px-3"
           style={{ color: "gray" }}>
            La técnica percutánea del zafiro es la práctica más innovadora en
            este campo, del que fuimos pioneros en nuestro país.
          </p>
        </div>
        <img
         className="w-full"
          src={Img}
          alt="cardsimg"
        />
      </div>
    </div>
  );
}

export default FUESapphireCards;
