import React from "react";
import { Divider } from "primereact/divider";
function FUESapphireAcordion() {
  return (
    <div className="FUESapphireAccordion">
      <Divider align="center"> <h2 className="text-center"
        style={{
          padding: "30px",
          color: "#e75480",
        }}
      >
        ¿ Necesitas más información ?
      </h2>
      </Divider>
      <div
        className="accordion accordion-flush p-3 text-center"
        id="accordionFlushExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
              style={{ color: "#e75480", margin: '0 auto' }}
            >
              ¿ Cómo es nuestro procedimiento para el trasplante de pelo ?
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                Haces la reserva del vuelo, nos envías la copia por email, te
                reservamos la fecha de la operación y básicamente ya está. Así
                de fácil. Una vez en el hotel descansas bien para el día
                siguiente en que se te realizará el trasplante de pelo. Al día
                siguiente tendrás un chófer esperando en la puerta del hotel
                para llevarte al Hospital a la hora acordada, donde te estarán
                esperando los miembros del equipo que te van a intervenir.
                Dependiendo de la cantidad de unidades foliculares a implantar
                la intervención puede durar de 4 a 8 horas. Una vez finalizada
                la operación, vuelves a descansar al hotel. Al día siguiente te
                haremos una revisión. A los dos días, el primer lavado. Ahora
                sólo queda seguir las indicaciones de los médicos para hacer una
                buena y rápida recuperación. Tomarás, ese día o al día
                siguiente, el avión de vuelta a casa y ya está, habrás terminado
                con el incómodo y antiestético problema de la calvicie.
              </p>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                style={{ color: "#e75480" }}
              >
                ¿ Qué diferencia hay entre hacerlo en Turquía o en España ?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Ninguna. La técnica del trasplante de pelo por el método FUE es
                  la misma en Turquía, en España o en otros países del mundo. Se
                  extraen una a una las unidades foliculares de la zona donante y
                  se implantan en la zona receptora (calva). La diferencia más
                  notable es la experiencia y el precio. Turquía actualmente es el
                  país del mundo con más experiencia en trasplante de pelo por el
                  método FUE debido al gran número de operaciones que se realizan
                  cada año. Actualmente es una potencia mundial en el trasplante
                  capilar por el método FUE. Sus especialistas y sus clínicas
                  están a la vanguardia. Además los sueldos que allí cobran son
                  inferiores al de los españoles y el Gobierno Turco les concede
                  ayudas, con lo que se abarata mucho la operación. Son capaces,
                  además, de hacer macro operaciones de hasta 6.000 unidades
                  foliculares. En España no manejan esas cifras y generalmente se
                  requieren dos o tres intervenciones para conseguir los mismos
                  resultados. Con la consecuente molestia de realizarse este
                  proceso varias veces, varias anestesias, varios rapados de pelo,
                  varios postoperatorios y aumento del coste del trasplante.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
                style={{ color: "#e75480" }}
              >
                ¿ Cuánto tiempo se tarda en recuperarse totalmente ?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  La recuperación con el método FUE es inmediata, no es necesaria
                  tomar baja médica ya que no se ve alterada tu capacidad para
                  realizar tu vida cotidiana. No obstante es recomendable seguir
                  unas pequeñas instrucciones del médico. En cuanto a la
                  recuperación del cabello se verá el resultado final de 9 a 12
                  meses
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
                style={{ color: "#e75480" }}
              >
                ¿ Hay otros tratamientos que no requieran quirófano ?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Actualmente sólo hay unos pocos tratamientos clínicamente
                  comprobados que son válidos para tratar la caída del cabello.
                  Estos tratamientos son solo eficaces para detener o retrasar la
                  caída y para mejorar la calidad del pelo. Rara vez aumentan o
                  recuperan cabello, esto se ha dado en muy pocos casos. También
                  hay multivitaminas, champús, lociones, alimentación, etc. pero
                  lo que hacen es contribuir algo a una mejora en la calidad del
                  cabello y quizá una caída más lenta del mismo. A día de hoy la
                  única solución para el problema de la calvicie es el trasplante
                  de pelo o injerto capilar, ni siquiera vamos a comentar las
                  soluciones de injerto de fibras o micropigmentación, que en
                  nuestra humilde opinión no son buenas soluciones
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
              style={{ color: "#e75480" }}
            >
              ¿ Cómo es el posoperatorio del trasplante capilar ?
            </button>
          </h2>
          <div
            id="flush-collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFive"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                Saldrás del hospital andando por tu propio pie tras la
                intervención. Puede que quizás te encuentres un poco cansado o
                aturdido por permanecer varias horas tumbado mientras te
                realizan el trasplante. Según vaya pasando la anestesia tendrás
                algo de molestias y dolor de cabeza. Toma los medicamentos que
                te facilitará el médico para que se te alivien estas molestias
                cuanto antes. Tendrás que seguir las indicaciones que te den
                nuestros especialistas en cuanto a los medicamentos que deberás
                tomar inmediatamente después de la operación, los días
                siguientes, la postura para dormir, utilización de la almohada
                anatómica, higiene, etc. Nada complicado. Podrás hacer vida
                totalmente normal, la mayoría se va de turismo por Estambul al
                día siguiente, aunque recomendamos que te tomes unos días de
                descanso para estar tranquilo y recuperarte cuanto antes.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingSix">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSix"
              aria-expanded="false"
              aria-controls="flush-collapseSix"
              style={{ color: "#e75480" }}
            >
              ¿ Cuándo podré ver los resultados definitivos ?
            </button>
          </h2>
          <div
            id="flush-collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingSix"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                No nos engañemos, el proceso es algo lento. Los pelos
                trasplantados al principio son muy débiles y se caen, es lo
                normal. A las dos o tres semanas se habrán caído los pelos
                trasplantados y puede que también algunos tuyos debido al
                estrés. Te verás incluso peor que antes de hacerte la operación.
                Este periodo puede durar hasta los dos o tres meses en que los
                nuevos pelos y los que ya tenías empiezan de nuevo a crecer. A
                partir de los cuatro meses podrás ver alguna mejoría y es ya
                hacia los seis meses cuando verás claramente los resultados, que
                mejorarán hasta los nueves meses. Pero hasta los 12 meses no
                verás el resultado definitivo.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingSeven">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSeven"
              aria-expanded="false"
              aria-controls="flush-collapseSeven"
              style={{ color: "#e75480" }}
            >
              ¿ Qué riesgos tiene la operación con el método FUE ?
            </button>
          </h2>
          <div
            id="flush-collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingSeven"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                No cabe duda que no deja de ser una intervención quirúrgica. En
                la mayoría de los casos no exis
                te ningún tipo de complicación,
                pero se puede dar alguno de los siguientes riesgos: Infección:
                Como en cualquier intervención puede haber riesgo de infección,
                siendo controlada y tratada siguiendo instrucciones de su médico
                . Anestesia: En el trasplante capilar la anestesia utilizada es
                local por lo que no debe preocuparse, el riesgo es mínimo.
                Cicatrización: Es recomendable proteger las cicatrices del sol
                durante las primeras 2 semanas después de la intervención. Evita
                la exposición al sol ya que puede provocar una mayor
                pigmentación de la cicatriz con lo que resultará mucho más
                visible así como proteger la zona trasplantada ya que se puede
                oscurecer esta zona que ahora es muy sensible y más rosada que
                antes. Hinchazón: Algunos pacientes pueden llegar a padecer
                cierta hinchazón en la frente y en los párpados aunque de forma
                temporal, no debes asustarte, es normal. Esto puede ocurrir si
                no sigues las instrucciones de tu médico y duermes boca abajo
                los primeros días después de la operación, por lo que se
                recomienda dormir ligeramente inclinado o semisentado los
                primeros días para evitar este riesgo. Falta de sensibilidad: El
                paciente perderá sensibilidad durante varias semanas en la zona
                donante y receptora que irá recuperando poco a poco.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingEight">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseEight"
              aria-expanded="false"
              aria-controls="flush-collapseEight"
              style={{ color: "#e75480" }}
            >
              ¿ Es dolorosa la intervención ?
            </button>
          </h2>
          <div
            id="flush-collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingEight"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                La operación de trasplante de pelo con la técnica FUE no es
                dolorosa, es una cirugía muy poco invasiva. La parte más
                dolorosa de la intervención es la anestesia y depende de cada
                persona. Son unos pocos pinchazos a nivel subcutáneo. A algunos
                no les molestan nada, a otros les molestan o incluso les duelen.
                En cualquier caso el dolor es muy soportable y además son solo
                unos pocos minutos al principio, luego ya no se siente nada.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingNine">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseNine"
              aria-expanded="false"
              aria-controls="flush-collapseNine"
              style={{ color: "#e75480" }}
            >
              ¿ Puede cualquiera hacerse un trasplante de pelo ?
            </button>
          </h2>
          <div
            id="flush-collapseNine"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingNine"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                En principio casi todo el mundo, hombres y mujeres, pueden
                hacerse un trasplante de pelo mediante el método FUE, pero
                insistimos en que es el especialista el que lo debe recomendar o
                no en función de cada paciente, de la calidad y la cantidad de
                pelo de zona donante del paciente, así como su estado de salud
                general, los medicamentos que el paciente tome, tratamientos,
                etc. Es importante informar al especialista de todo esto y
                enviarle fotos para que pueda hacer una primera valoración.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTen">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTen"
              aria-expanded="false"
              aria-controls="flush-collapseTen"
              style={{ color: "#e75480" }}
            >
              ¿ Qué método de trasplante capilar o injerto de pelo es el mejor ?
            </button>
          </h2>
          <div
            id="flush-collapseTen"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTen"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p style={{ color: "gray" }}>
                Aunque no puede establecerse una regla fija, ya que cada
                paciente es un mundo, actualmente está generalizado que el
                método FUE está considerado como la técnica de trasplante
                capilar más avanzada que existe, a pesar de ser más laboriosa de
                realizar. Las dos técnicas tienen sus ventajas y sus
                inconvenientes. Personalmente nos inclinamos también por el
                método FUE, porque no deja cicatrices, el tiempo de recuperación
                es más corto y hay pocas molestias postoperatorias. Hay personas
                que se realizan un trasplante capilar por el método FUE para
                arreglar estéticamente la cicatriz dejada por un trasplante
                capilar realizado con la técnica FUSS. De todas formas es el
                especialista quien debería decidir o aconsejar en función de
                cada paciente qué técnica realizar.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FUESapphireAcordion;
