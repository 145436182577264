import React from "react";
import { Link } from "react-router-dom";
import {
  FaPhone,
  FaMailBulk,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

export default function PageFooter() {
  return (
    <footer
      className="grid mt-6 pt-4 mx-0 px-3 text-center"
      style={{
        background: " rgb(47,50,50)",
        background: " -moz-linear-gradient(209deg, rgba(47,50,50,1) 9%, rgba(0,0,0,1) 61%)",
        background: "-webkit-linear-gradient(209deg, rgba(47,50,50,1) 9%, rgba(0,0,0,1) 61%)",
        background: "linear-gradient(209deg, rgba(47,50,50,1) 9%, rgba(0,0,0,1) 61%)",
        filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#2f3232,endColorstr=#000000,GradientType=1)"
      }}
    >
      <div className="col-12 mt-2">
        <h5 className="text-white text-xl uppercase mt-3" id="contactanos">Contáctanos</h5>
        <div className="flex justify-content-center align-items-center mt-5 text-white">
          <a href="tel:+34613515111" target="_blank" className="flex justify-content-center align-items-center text-white no-underline px-3">
            <FaPhone style={{ color: '#E75480', fontSize: '1.5rem' }} />
            <span className="px-1">+34613515111</span>
          </a>
          <a href="mailto:info@estethicagroup.es" target="_blank" className="flex justify-content-center align-items-center text-white no-underline px-3">
            <FaMailBulk style={{ color: '#E75480', fontSize: '1.5rem' }} />
            <span className="px-1">info@estethica.es</span>
          </a>
        </div>
      </div>
      <div className="col-12">
        <div className="flex align-items-center justify-content-center text-sm h-auto">
          <Link to="/politica-privacidad"
            className="text-400  politicas">
            <span className="underline mr-2 md:px-3">Política de Privacidad</span>
          </Link>
          <Link to="/politica-cookies"
            className="text-400  politicas"
          ><span className="underline md:px-3">Política de Cookies</span>
          </Link>
          <Link to="/mapa-sitio"
            className="text-400  politicas">
            <span className="underline ml-2 md:px-3">Mapa del Sitio</span>
          </Link>
        </div>
      </div>
      <div className="col-12 mb-2">
        <div className="flex justify-content-center  text-2xl h-auto">
          <a
            href="https://www.facebook.com/estethicaesp/"
            target="_blank"
            className="text-white no-underline"
          >
            <FaFacebook className="text-600 mx-1" id="facebook" />
          </a>
          <a
            href="https://twitter.com/estethica_esp"
            target="_blank"
            className="text-white no-underline"
          >
            <FaTwitter className="text-600 mx-1" id="twitter" />
          </a>
          <a
            href="https://www.instagram.com/estethica_esp/"
            target="_blank"
            className="text-white no-underline"
          >
            <FaInstagram className="text-600 mx-1" id="instagram" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC6YiyOoku2hn6wOqfpvN2_Q?view_as="
            target="_blank"
            className="text-white no-underline"
          >
            <FaYoutube className="text-600 mx-1" id="youtube" />
          </a>
        </div>
      </div>
    </footer>
  );
}
