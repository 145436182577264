import React from "react";
import Navbar from "../../Homepage/NavBar";
import AboutUsCardsImg from "./AboutUsCardsImg";
import AboutUsCards from "./AboutUsCards";
import PageFooter from "../../Homepage/PageFooter";
import AboutUsCardsImg1 from "./AboutCardsImg1";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../App.css";


function AboutUs() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="AboutUs">
      <progress max={100} value={0}></progress>
      <Navbar />
      <AboutUsCardsImg />
      <AboutUsCards />
      <AboutUsCardsImg1 />
      <PageFooter />
    </div>
  );
}

export default AboutUs;