import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/9.jpeg";
import { Divider } from "primereact/divider";

function LaserLipolysisPlusCardsImg() {
  return (
    <div className="LaserLipolysisPlusCardsImg" style={{ paddingTop: '60px' }}>
      <div className="card w-full text-center" style={{
        padding: "30px",
        color: "#e75480",
      }}>
        <Divider align="center"> <h2 className="px-3">Láser Lipolysis Plus en Turquía</h2></Divider>
        <p className="px-3" style={{ color: "gray" }}>
          Laser Lipolysis Plus es una técnica que reduce volumen y moldea el
          cuerpo mediante la estimulación de las células que proporcionan
          elasticidad al tejido, y a la vez destruye las células adiposas
          aplicando energía láser al tejido adiposo para obtener una piel más
          firme.
        </p>
        <p className="px-3" style={{ color: "gray" }}>
          Laser Lipolysis Plus, aprobado por la FDA (Administración Americana
          de Alimentos y Medicamentos), es la tecnología de láser lipolítico
          más moderna del mercado y utiliza longitudes de onda dobles para
          asegurar resultados exitosos.
        </p>
        <img className="w-full"
          src={Img}
          alt="cardsimg"
        />
      </div>
    </div >
  );
}

export default LaserLipolysisPlusCardsImg;
