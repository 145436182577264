import React from "react";
import { Card } from "primereact/card";

function DHIPlusGoldCardsBody() {
  return (
    <div className="DHIPlusGoldCardsBody">
      <div className="card w-full text-center"
        style={{
          padding: "0 30px"
        }}
      >
        <h2 className="p-3"
          style={{ color: "#e75480" }}>
          ¿ Qué es DHI Plus Gold?
        </h2>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}>
          La técnica DHI Plus Gold, es una técnica avanzada de trasplante
          capilar que ha conseguido reducir casi a cero la pérdida de injerto
          y que supone una reducción de 1/3 en la duración de la intervención.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}>
          La característica más importante del trasplante de cabello con la
          técnica DHI Plus Gold es que no requiere puntos, cicatrices ni
          cortes. El propósito de esta técnica es asegurar que los injertos
          tomados para ser sembrados desde la parte posterior de la cabeza
          sean más sanos y fuertes al minimizar el tiempo que están afuera.
          Los injertos tomados de la parte sana de la cabeza se mantienen en
          una solución especial, evitando así la muerte de los folículos
          pilosos. Esta solución es una solución especial que se utiliza
          durante el trasplante de órganos y garantiza que los órganos
          internos se mantengan vivos durante mucho tiempo.
        </p>
        <h2 className="p-3" style={{ color: "#e75480" }}>
          Etapas técnicas DHI Plus Gold
        </h2>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          El proceso comienza con el examen de la estructura del cabello
          del paciente con un analizador de cabello y luego se analiza la
          estructura del cuero cabelludo y la dirección de los folículos
          pilosos.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          El área de la línea frontal del cabello se crea de acuerdo con
          la forma de la cabeza de la persona y el estado más natural.
          Después de este procedimiento, la anestesia local se realiza con
          el dispositivo de anestesia sin dolor.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          Los injertos recolectados se toman de la solución especial para
          ser plantados junto con la aguja de punta de oro desarrollada
          por Estethica y el proceso de aplicación del Complejo
          Multivitamínico se realiza con vapor de ozono.
        </p>

        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          Al final del proceso de siembra, el paciente es dado de alta del
          área del donante aplicando crema dorada.
        </p>

        <h2 className="p-3" style={{ color: "#e75480" }}>
          Etapas técnicas DHI Plus Gold
        </h2>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          Realizar ranuras y reemplazar la raíz del cabello al mismo
          tiempo permite que los folículos pilosos recolectados se
          coloquen en su lugar sin esperar mucho tiempo.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          La pérdida de las raíces en la solución especial se minimiza.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          Permite una plantación mucho más frecuente y apariencias más
          naturales.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          Gracias a la práctica aguja con punta de oro , se reduce el
          tiempo de espera de las raíces durante el trasplante de cabello
          . Por lo tanto, el riesgo de pérdida de raíz se minimiza.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          La punta de oro minimiza el riesgo de daño tisular y reacciones
          alérgicas.
        </p>
        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          El pelo existente no está dañado.
        </p>

        <p
          className="px-3"
          style={{ color: "gray", textAlign: "center" }}
        >
          El paciente experimenta una rápida recuperación.
        </p>
        <p className="p-3" style={{ color: "gray", textAlign: "center" }}>
          <strong>* ADVERTENCIA:</strong> Cada persona es única. Esto significa que la duración
          del tratamiento, la planificación y los resultados pueden ser
          diferentes para usted. La información que ve aquí es el promedio
          resultante de una amplia gama de muestras. Puede contactarnos para
          obtener información mas detallada a través del teléfono  649-633-448.
        </p>
      </div>
    </div>
  );
}

export default DHIPlusGoldCardsBody;
