import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Navbar from "./Homepage/NavBar";
import Photo from "./Homepage/Photo";
import Form from "./Homepage/Form";
import Cards from "./Homepage/Cards";
import CardsImg from "./Homepage/CardsImg";
import Carousel from "./Homepage/Carousel";
import PageFooter from "./Homepage/PageFooter";
import  "../App.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";


export default function Home() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <progress max={100} value={0}></progress>
      <Navbar />
      <Photo />
      <Form />
      <Cards />
      <CardsImg />
      <Carousel />
      <PageFooter />
    </div>
  );
}
