import React from "react";
import Navbar from "../../Homepage/NavBar";
import PageFooter from "../../Homepage/PageFooter";
import MedicalEquipmentCardsImg from "./MedicalEquipmentCardsImg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../App.css";



function MedicalEquipment() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}, []);

    return (
      <div className="MedicalEquipment">
        <progress max={100} value={0}></progress>
        <Navbar />
        <MedicalEquipmentCardsImg style={{paddingTop:'40px'}}/>
        <PageFooter/>
      </div>
    );
  }
  
  export default MedicalEquipment;