import React from 'react';
import {Link} from "react-router-dom";
import { FaRegSadCry } from "react-icons/fa";
import Navbar from '../Homepage/NavBar';
import Footer from '../Homepage/PageFooter';

export default function PageNotFound404() {
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Navbar />
            <div className='flex align-items-center justify-content-center w-full h-screen background'>
                <div className='grid mx-0 error404'>
                    <div className='col-12 text-center' style={{ paddingTop: '120px' }}>
                        <h1 className='text-6xl md:text-8xl md:font-semibold'>404</h1><br />
                        <span className='text-md'>Lo sentimos, la ruta a la que intenta acceder,<br /> no existe.</span>
                    </div>
                    <div className='col-12' style={{ marginTop: '40px' }}>
                        <div className='flex align-items-center justify-content-center w-full text-center'>
                            <Link to="/" className="link_404 no-underline">Regresar al inicio</Link>
                        </div>
                    </div>
                </div>
                {/* <span className='four_zero_four_bg text-center text-5xl' style={{ marginTop: '80px' }}>ERROR_404</span> */}
            </div>
            {/* <Footer/> */}
        </div>
    )
}