import React from "react";
import { Card } from "primereact/card";

function BreastAestheticsCardsBody() {
  return (
    <div className="BreastAestheticsCardsBody">
      <div className="card w-full text-center"
        style={{
          padding: "30px",
          color: "gray"
        }}>
        <h2
          className="p-3"
          style={{ color: "#e75480" }}>
          ¿ Por qué realizarse un aumento de pecho ?
        </h2>
        <p
          className="px-3">
          La operación estética de aumento de pecho se realiza típicamente
          para agrandar los senos que son pequeños, subdesarrollados o que se
          han vuelto más pequeños después de dar a luz.
        </p>
        <p
          className="px-3">
          Percepción corporal y menor confianza en sí mismo causada por
          los senos pequeños.
        </p>
        <p
          className="px-3 ">
          Relación refutada en el tamaño de la cintura y de los senos.
        </p>
        <p
          className="px-3">
          Pechos caídos, caídos y encogidos después del parto.
        </p>
        <p
          className="px-3">
          Reducción y deformación de los senos después del aumento y la
          pérdida de peso.
        </p>
        <p
          className="px-3">
          Pechos asimétricos.
        </p>
        <p
          className="px-3">
          El volumen de los senos aumenta con implantes de silicona hechos de
          materiales naturales y que se insertan debajo del tejido mamario.
        </p>
        <p
          className="px-3">
          El paciente y el médico deciden juntos el tamaño y la forma del
          implante, siempre que los implantes elegidos no superen unos límites
          fisiológicos.
        </p>
        <h2
          className="p-3"
          style={{ color: "#e75480" }}>
          Antes de la cirugía...
        </h2>
        <p
          className="px-3">
          Antes de la operación de aumento de pecho se debe realizar un examen
          de mamas y ser evaluada con ultrasonografía y mamografías en
          situaciones en las que pueden ser de mayor riesgo y para pacientes
          de 40 años o más.
        </p>
        <p
          className="px-3">
          <strong>Rango de edad: </strong>
          Se recomienda a partir de los 18 años de edad, una vez que el
          desarrollo de los senos haya terminado. También se puede
          realizar en pacientes a partir de los 50 años, siempre y cuando
          se encuentren en buen estado de salud. Sin embargo, es posible
          realizar la operación en pacientes menores de 18 años con el
          consentimiento de sus padres.
        </p>
        <p
          className="px-3">
          <strong>Métodos quirúrgicos: </strong>
          Los implantes utilizados para aumentar el volumen de los senos
          se pueden ser insertados a través del pezón, el pliegue
          inframamario o la axila con incisiones de 3-4 cm de largo o a
          través del ombligo.
        </p>
        <p
          className="px-3">
          <strong>Forma del implante mamario: </strong>
          Los implantes mamarios se dividen en 2 grupos principales:
          redondos y en forma de lágrima (anatómicos).
        </p>
        <h2
          className="p-3"
          style={{ color: "#e75480" }}>
          En el postoperatorio...
        </h2>
        <p
          className="px-3 ">
          El dolor postoperatorio se puede controlar fácilmente con
          analgésicos y desaparece en un día o dos. La cicatrización se
          desvanece con el tiempo, volviéndose débil 6 meses – 1 año después.
        </p>
        <p
          className="px-3">
          Los bajos niveles de hinchazón y los cambios de color desaparecen en
          pocos días y los senos serán más grandes de lo previsto y apretados
          durante dos semanas después. Puede ducharse y empezar a trabajar de
          3 a 5 días después de la operación y el médico le hará revisiones a
          las que deberá acudir en períodos específicos (1, 3, 6 meses, etc.).
        </p>
        <p
          className="px-3">
          Usted puede salir a caminar, pero las actividades que fuerzan
          los músculos del pecho (tenis, natación, etc.) deben evitarse
          durante 6-8 semanas.
        </p>
        <p
          className="px-3">
          Aunque los pacientes pueden volver al trabajo en pocos días, no
          se recomienda conducir, levantar objetos pesados y levantar los
          brazos hacia arriba durante 1 y 3 semanas respectivamente.
        </p>
        <p
          className="px-3">
          Se debe evitar la sudoración durante los primeros 15 días, ya
          que es el período en el que se realizan las incisiones.
        </p>
        <p className="p-3">
          <strong>* ADVERTENCIA:</strong> Cada persona es única. Esto significa que la duración
          del tratamiento, la planificación y los resultados pueden ser
          diferentes para usted. La información que ve aquí es el promedio
          resultante de una amplia gama de muestras. Puede contactarnos para
          obtener información mas detallada a través del teléfono 649 633 448.
        </p>
      </div>
    </div>
  );
}

export default BreastAestheticsCardsBody;
