import React from "react";
import Navbar from "../../../Homepage/NavBar";
import FUESapphireCardsImg from "./FUESapphireCardsImg";
import FUESapphireCards from "./FUESapphireCards";
import FUESapphireCardsImgBody from "./FUESapphireCardsImgBody";
import FUESapphireAcordion from "./FUESapphiroAcordion";
import PageFooter from "../../../Homepage/PageFooter";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../../App.css";


function FUESapphire() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="FUESapphire">
      <progress max={100} value={0}></progress>
      <Navbar />
      <FUESapphireCardsImg style={{ paddingTop: '40px' }} />
      <FUESapphireCards />
      <FUESapphireCardsImgBody />
      <FUESapphireAcordion />
      <PageFooter />
    </div>
  );
}

export default FUESapphire;
