const prefixWorldBD = [
    { country: "Afganistán", prefix: "+93", innerStr: " ", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Albania", prefix: "+355", innerStr: " ", minValue: 3, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Alemania", prefix: "+49", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 13, maxExact: false },
    { country: "Andorra", prefix: "+376", innerStr: " ", minValue: 6, minExact: true, middleValue: 8, maxValue: 9, maxExact: true },
    { country: "Angola", prefix: "+244", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Anguila", prefix: "+1 264", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Antigua y Barbuda", prefix: "+1 268", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Antillas Neerlandesas", prefix: "+599", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Argelia", prefix: "+213", innerStr: " ", minValue: 8, minExact: true, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Argentina", prefix: "+54", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Armenia", prefix: "+374", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Aruba", prefix: "+297", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Ascensión", prefix: "+247", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 4, maxExact: true },
    { country: "Australia", prefix: "+61", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 15, maxExact: false },
    { country: "Austria", prefix: "+43", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 15, maxExact: false },
    { country: "Azerbaiyán", prefix: "+994", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },

    { country: "Bahamas", prefix: "+1 242", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Bahrein", prefix: "+973", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Bangladesh", prefix: "+880", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Barbados", prefix: "+1 246", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Bélgica", prefix: "+32", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Belice", prefix: "+501", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Benín", prefix: "+229", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Bermudas", prefix: "+1 441", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Bielorrusia", prefix: "+375", innerStr: " ", minValue: 9, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Birmania (Myanmar)", prefix: "+95", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Bolivia", prefix: "+591", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Bosnia y Herzegovina", prefix: "+387", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Botsuana", prefix: "+267", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Brasil", prefix: "+55", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Brunei", prefix: "+673", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Bulgaria", prefix: "+359", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Burkina Faso", prefix: "+226", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Burundi", prefix: "+257", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Bután", prefix: "+975", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },

    { country: "Cabo Verde", prefix: "+238", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Camboya", prefix: "+855", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Camerún", prefix: "+237", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Canadá", prefix: "+1", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Chad", prefix: "+235", innerStr: " ", minValue: 6, minExact: true, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Chile", prefix: "+56", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "China", prefix: "+86", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Chipre", prefix: "+357", innerStr: " ", minValue: 8, minExact: true, middleValue: null, maxValue: 11, maxExact: true },
    { country: "Colombia", prefix: "+57", innerStr: " ", minValue: 8, minExact: true, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Comoras", prefix: "+269", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Congo, República del", prefix: "+242", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 7, maxExact: false },
    { country: "Congo, República Democrática del", prefix: "+243", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Corea del Norte", prefix: "+850", innerStr: " ", minValue: 3, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Corea del Sur", prefix: "+82", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Costa de Marfil", prefix: "+225", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Costa Rica", prefix: "+506", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Croacia", prefix: "+385", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Cuba", prefix: "+53", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 8, maxExact: false },

    { country: "Departamentos y territorios franceses en el Océano Índico", prefix: "+262", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Diego García", prefix: "+246", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Dinamarca", prefix: "+45", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Dominica", prefix: "+1 767", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Ecuador", prefix: "+593", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Egipto", prefix: "+20", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "El Salvador", prefix: "+503", innerStr: " ", minValue: 7, minExact: true, middleValue: 8, maxValue: 11, maxExact: true },
    { country: "Emiratos Árabes Unidos", prefix: "+971", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Eritrea", prefix: "+291", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Eslovaquia", prefix: "+421", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Eslovenia", prefix: "+386", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "España", prefix: "+34", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Estados Unidos", prefix: "+1", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Estonia", prefix: "+372", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Etiopía", prefix: "+251", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Filipinas", prefix: "+63", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Finlandia", prefix: "+358", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Fiyi", prefix: "+679", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Francia", prefix: "+33", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },

    { country: "Gabón", prefix: "+241", innerStr: " ", minValue: 6, minExact: true, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Gambia", prefix: "+220", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Georgia", prefix: "+995", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Ghana", prefix: "+233", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Gibraltar", prefix: "+350", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Granada", prefix: "+1 473", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Grecia", prefix: "+30", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Groenlandia", prefix: "+299", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Guadalupe", prefix: "+590", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Guam", prefix: "+1 671", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Guatemala", prefix: "+502", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Guayana Francesa", prefix: "+594", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Guinea", prefix: "+224", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Guinea Ecuatorial", prefix: "+240", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Guinea-Bissau", prefix: "+245", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Guyana", prefix: "+592", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Haití", prefix: "+509", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Honduras", prefix: "+504", innerStr: " ", minValue: 7, minExact: true, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Hong Kong", prefix: "+852", innerStr: " ", minValue: 4, minExact: true, middleValue: 8, maxValue: 9, maxExact: true },
    { country: "Hungría", prefix: "+36", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },

    { country: "India", prefix: "+91", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Indonesia", prefix: "+62", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Irak", prefix: "+964", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Irán", prefix: "+98", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Irlanda", prefix: "+353", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Islandia", prefix: "+354", innerStr: " ", minValue: 7, minExact: true, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Islas Caimán", prefix: "+1 345", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Islas Cook", prefix: "+682", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 5, maxExact: true },
    { country: "Islas Feroe", prefix: "+298", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Islas Malvinas (Falkland Islands)", prefix: "+500", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 5, maxExact: true },
    { country: "Islas Marshall", prefix: "+692", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Islas Salomón", prefix: "+677", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 5, maxExact: true },
    { country: "Islas Turcas y Caicos", prefix: "+1 649", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Islas Vírgenes", prefix: "+1 340", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Islas Vírgenes Británicas", prefix: "+1 284", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Israel", prefix: "+972", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Italia", prefix: "+39", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 11, maxExact: true },
    { country: "Jamaica", prefix: "+1 876", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Japón", prefix: "+81", innerStr: " ", minValue: 9, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Jordania", prefix: "+962", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Kazajstán", prefix: "+7", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Kenia", prefix: "+254", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Kirguistán", prefix: "+996", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Kiribati", prefix: "+686", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 5, maxExact: true },
    { country: "Kuwait", prefix: "+965", innerStr: " ", minValue: 7, minExact: true, middleValue: null, maxValue: 8, maxExact: true },

    { country: "Laos", prefix: "+856", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Lesotho", prefix: "+266", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Letonia", prefix: "+371", innerStr: " ", minValue: 7, minExact: true, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Líbano", prefix: "+961", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Liberia", prefix: "+231", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Libia", prefix: "+218", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Liechtenstein", prefix: "+423", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Lituania", prefix: "+370", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Luxemburgo", prefix: "+352", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Macao", prefix: "+853", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Macedonia", prefix: "+389", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Madagascar", prefix: "+261", innerStr: " ", minValue: 9, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Malasia", prefix: "+60", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Malawi", prefix: "+265", innerStr: " ", minValue: 7, minExact: true, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Maldivas", prefix: "+960", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Malí", prefix: "+223", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Malta", prefix: "+356", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Marruecos", prefix: "+212", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Martinica", prefix: "+596", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Mauricio", prefix: "+230", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Mauritania", prefix: "+222", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Mayotte", prefix: "+269", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 15, maxExact: true },
    { country: "México", prefix: "+52", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Micronesia, Estados Federados de", prefix: "+691", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Moldavia", prefix: "+373", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Mónaco", prefix: "+377", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Mongolia", prefix: "+976", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Montenegro", prefix: "+382", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Montserrat", prefix: "+1 664", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Mozambique", prefix: "+258", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },

    { country: "Namibia", prefix: "+264", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Nauru", prefix: "+674", innerStr: " ", minValue: 4, minExact: true, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Nepal", prefix: "+977", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Nicaragua", prefix: "+505", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Níger", prefix: "+227", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Nigeria", prefix: "+234", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Niue", prefix: "+683", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 4, maxExact: true },
    { country: "Noruega", prefix: "+47", innerStr: " ", minValue: 5, minExact: true, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Nueva Caledonia", prefix: "+687", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Nueva Zelanda", prefix: "+64", innerStr: " ", minValue: 3, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Omán", prefix: "+968", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Países Bajos", prefix: "+31", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Pakistán", prefix: "+92", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Palau", prefix: "+680", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Panamá", prefix: "+507", innerStr: " ", minValue: 7, minExact: true, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Papúa Nueva Guinea", prefix: "+675", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Paraguay", prefix: "+595", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Perú", prefix: "+51", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Polinesia Francesa", prefix: "+689", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Polonia", prefix: "+48", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Portugal", prefix: "+351", innerStr: " ", minValue: 9, minExact: true, middleValue: null, maxValue: 11, maxExact: true },
    { country: "Puerto Rico", prefix: "+1 787", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Puerto Rico", prefix: "+1 939", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Qatar", prefix: "+974", innerStr: " ", minValue: 6, minExact: true, middleValue: 7, maxValue: 10, maxExact: true },

    { country: "Reino Unido", prefix: "+44", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "República Centroafricana", prefix: "+236", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "República Checa", prefix: "+420", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "República Dominicana", prefix: "+1 809", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "República Dominicana", prefix: "+1 829", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Ruanda", prefix: "+250", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Rumanía", prefix: "+40", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Rusia", prefix: "+7", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Sahara Occidental", prefix: "+212", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 15, maxExact: true },
    { country: "Samoa", prefix: "+685", innerStr: " ", minValue: 3, minExact: false, middleValue: null, maxValue: 7, maxExact: false },
    { country: "Samoa Americana", prefix: "+1 684", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "San Cristóbal y Nieves", prefix: "+1 869", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "San Marino", prefix: "+378", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "San Pedro y Miquelón", prefix: "+508", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "San Vicente y las Granadinas", prefix: "+966", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "San Vicente y las Granadinas", prefix: "+1 784", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Santa Helena", prefix: "+290", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 4, maxExact: true },
    { country: "Santa Lucía", prefix: "+1 758", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Santa Sede (Ciudad del Vaticano)", prefix: "+418", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 11, maxExact: true },
    { country: "Santo Tomé y Príncipe", prefix: "+239", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Senegal", prefix: "+221", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Serbia", prefix: "+381", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Seychelles", prefix: "+248", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Sierra Leona", prefix: "+232", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Singapur", prefix: "+65", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Siria", prefix: "+963", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Somalia", prefix: "+252", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Sri Lanka", prefix: "+94", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Suazilandia", prefix: "+268", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 8, maxExact: false },
    { country: "Sudáfrica", prefix: "+27", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Sudán", prefix: "+249", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Suecia", prefix: "+46", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 13, maxExact: false },
    { country: "Suiza", prefix: "+41", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 12, maxExact: false },
    { country: "Surinam", prefix: "+597", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 7, maxExact: false },

    { country: "Tailandia", prefix: "+66", innerStr: " ", minValue: 8, minExact: true, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Taiwán", prefix: "+886", innerStr: " ", minValue: 8, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Tanzania", prefix: "+255", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Tayikistán", prefix: "+992", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Territorio del Norte", prefix: "+1 670", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Timor-Leste", prefix: "+670", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Togo", prefix: "+228", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Tokelau", prefix: "+690", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 4, maxExact: true },
    { country: "Tonga", prefix: "+676", innerStr: " ", minValue: 5, minExact: true, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Trinidad y Tobago", prefix: "+1 868", innerStr: "", minValue: null, minExact: false, middleValue: null, maxValue: 7, maxExact: true },
    { country: "Túnez", prefix: "+216", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Turkmenistán", prefix: "+993", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 8, maxExact: true },
    { country: "Turquía", prefix: "+90", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Tuvalu", prefix: "+688", innerStr: " ", minValue: 5, minExact: true, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Ucrania", prefix: "+380", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Uganda", prefix: "+256", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Uruguay", prefix: "+598", innerStr: " ", minValue: 4, minExact: false, middleValue: null, maxValue: 11, maxExact: false },
    { country: "Uzbekistán", prefix: "+998", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Vanuatu", prefix: "+678", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 7, maxExact: false },
    { country: "Venezuela", prefix: "+58", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 10, maxExact: true },
    { country: "Vietnam", prefix: "+84", innerStr: " ", minValue: 7, minExact: false, middleValue: null, maxValue: 10, maxExact: false },
    { country: "Wallis y Futuna", prefix: "+681", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Yemen", prefix: "+967", innerStr: " ", minValue: 6, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
    { country: "Yibuti", prefix: "+253", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 6, maxExact: true },
    { country: "Zambia", prefix: "+260", innerStr: " ", minValue: null, minExact: false, middleValue: null, maxValue: 9, maxExact: true },
    { country: "Zimbabue", prefix: "+263", innerStr: " ", minValue: 5, minExact: false, middleValue: null, maxValue: 9, maxExact: false },
];

export function getListPrefixWorld() {
    return prefixWorldBD.map(ele => {
        return { label: `${ele.prefix} ${ele.country}`, value: ele.prefix, lengthStr: ele.maxValue };
    });
}
export function getMaxLenght(prefix) {
    const itemPrefixWorld = prefixWorldBD.find(ele => ele.prefix === prefix);
    return itemPrefixWorld.maxValue;
}
export function isValidPhone(prefix, phone) {
    const itemPrefixWorld = prefixWorldBD.find(ele => ele.prefix === prefix);

    if (!itemPrefixWorld) {
        return null;
    }
    
    if (itemPrefixWorld.maxValue < phone.length) {
        return `Teléfono incorrecto. Máximo ${itemPrefixWorld.maxValue} dígitos`;
    }
    if (itemPrefixWorld.minValue === null
        && itemPrefixWorld.maxValue !== phone.length
    ) {
        return `Teléfono incorrecto. Debe tener ${itemPrefixWorld.maxValue} dígitos`;
    }
    if (itemPrefixWorld.minValue !== null
        && itemPrefixWorld.minExact
        && itemPrefixWorld.middleValue === null
        && (itemPrefixWorld.minValue !== phone.length
            || itemPrefixWorld.maxValue !== phone.length)
    ) {
        return `Teléfono incorrecto. Debe tener ${itemPrefixWorld.minValue} ó ${itemPrefixWorld.maxValue} dígitos`;
    }
    if (itemPrefixWorld.minValue !== null
        && itemPrefixWorld.minExact
        && itemPrefixWorld.middleValue !== null
        && (itemPrefixWorld.minValue !== phone.length
            || itemPrefixWorld.middleValue !== phone.length
            || itemPrefixWorld.maxValue !== phone.length)
    ) {
        return `Teléfono incorrecto. Debe tener ${itemPrefixWorld.minValue}, ${itemPrefixWorld.middleValue} ó ${itemPrefixWorld.maxValue} dígitos`;
    }
    if (itemPrefixWorld.minValue !== null
        && !itemPrefixWorld.minExact
        && itemPrefixWorld.minValue > phone.length
    ) {
        return `Teléfono incorrecto. Debe tener de ${itemPrefixWorld.minValue} a ${itemPrefixWorld.maxValue} dígitos`;
    }

    return true;
}

export function getStrPhoneJoined (prefix, phone) {
    const itemPrefixWorld = prefixWorldBD.find(ele => ele.prefix === prefix);

    if (!itemPrefixWorld) {
        return null;
    }

    return prefix + itemPrefixWorld.innerStr + phone;
}