import React from "react";
import Navbar from "../../../Homepage/NavBar";
import LaserLipolysisPlusCardsImg from "./LaserLipolysisPlusCardsImg";
import LaserLipolysisPlusCards from "./LaserLipolysisPlusCards";
import LaserLipolysisPlusCardsBody from "./LaserLipolysisPlusCardsBody";
import PageFooter from "../../../Homepage/PageFooter";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../../App.css";

function LaserLipolysisPlus() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="LaserLipolysisPlus">
      <progress max={100} value={0}></progress>
      <Navbar />
      <LaserLipolysisPlusCardsImg style={{ paddingTop: '40px' }} />
      <LaserLipolysisPlusCards />
      <LaserLipolysisPlusCardsBody />
      <PageFooter />
    </div>
  );
}

export default LaserLipolysisPlus;