import React from "react";
import { Card } from "primereact/card";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import OpacityIcon from "@mui/icons-material/Opacity";
import SpaIcon from "@mui/icons-material/Spa";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import AttributionIcon from "@mui/icons-material/Attribution";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Divider } from "primereact/divider";
function AboutUsCards() {
  return (
    <div className="AboutUsCards text-center w-full" style={{
      padding: "30px",
      color: "##0076bf",
    }}>
      <Divider
        align="center">
        <h2
          className="px-3"
          style={{ color: '#e75480' }}>
          ¿ Por qué elegirnos ?
        </h2>
      </Divider>
      <h5 className="p-3" style={{ color: "#e75480" }}>
        Especialistas en trasplantes capilares y tratamientos de estética
      </h5>
      <div className="grid mx-0 my-4">
        <div className="col-12 md:col-2 mx-auto">
          <div className="flex align-center justify-content-center my-2">
            <AccountBoxIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Equipo médico altamente cualificado.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <LocalHospitalIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Habitaciones de primera clase para cada paciente.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <OpacityIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 max-w-15rem" style={{ color: "gray" }}>
              Piscinas de fisioterapia y rehabilitación.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <SpaIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              SPA y Centro Wellness.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <HeartBrokenIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Centro de cirugía plástica y estética.
            </p>
          </div>
        </div>
        <div className="col-12 md:col-2 mx-auto">
          <div className="flex align-center justify-content-center my-2">
            <AttributionIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Cínicas dentales totalmente equipadas
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <DomainAddIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Unidades diseñadas para el trasplante capilar.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <LocationOnIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Execelente ubicación.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <AcUnitIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Centro de referencia tanto en Turquía como a nivel mundial.
            </p>
          </div>
          <div className="flex align-center justify-content-center my-2">
            <QuestionAnswerIcon style={{ color: "#08c", fontSize: '2rem' }} />
            <p className="px-3 w-15rem" style={{ color: "gray" }}>
              Atención personalizada en árabe, alemán, inglés, francés, español,
              holandés, turco, ruso y azerbaiyano.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsCards;
