import React from "react";
import Navbar from "../../Homepage/NavBar";
import PageFooter from "../../Homepage/PageFooter";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../App.css";

function Tarifas() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <progress max={100} value={0}></progress>
      <Navbar />
      <div className="grid mx-0 w-full" style={{ padding: "140px 30px" }}>
        <Divider align="center">
          <h2 className="p-3" style={{ color: "#e75480" }}>
            Trasplante capilar
          </h2>
        </Divider>
        <div className="col-12 md:col-6 text-center offer1">
          <div className="mt-3">
            <h3 style={{ color: "#08c" }}>Método FUE con Zafiro</h3>
            <div className="card h-16rem mt-3">
              <div className="flex align-items-center justify-content-center">
                <div
                  className="flex align-items-center justify-content-center border-circle relative overflow-hidden"
                  style={{
                    background: "#e32486",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <span className="font-normal text-4xl text-0 z-3">
                    1.600€
                  </span>
                  <div
                    className="absolute border-circle-top z-1"
                    style={{
                      marginTop: "140px",
                      background: "#BD1E6F",
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                </div>
                <div
                  className="flex justify-content-center absolute mx-8 w-full h-20rem border-round"
                  style={{ marginTop: "410px" }}
                >
                  <div className="surface-0 font-semibold text-xl absolute z-3 p-3 text-center">
                    Oferta especial
                    <span className="block font-normal text-base mb-3">
                      Hasta 5.500 folículos
                    </span>
                    <div className="card text-left font-light text-base">
                      <span className="surface-300 px-2">
                        Procedimiento de Trasplante Capilar
                      </span>
                      <span className="px-2 border-1 border-300">
                        Traslados: aeropuerto - hotel - clínica y regreso
                        aeropuerto
                      </span>
                      <span className="surface-300 px-2">
                        3 noches de alojamiento en Hotel 4* estrellas con
                        desayuno
                      </span>
                      <span className="px-2 border-1 border-300">
                        Pre-anestesia sin dolor
                      </span>
                      <span className="surface-300 px-2">
                        Medicación Post-operatorio
                      </span>
                      <span className="px-2 border-1 border-300">
                        PRP (plasma rico en plaquetas)
                      </span>
                      <span className="surface-300 px-2">
                        <span className="font-semibold">* PACK + 100€</span>
                        (opcional) 3 noches de hotel 5* con desayuno
                      </span>
                      <Link to="/transplante-capilar/fue-con-zafiro" className="border-round-md  no-underline p-3 text-base mt-4 text-center" style={{ background: "#08c", color: '#fff' }}>Saber más sobre el método FUE con Zafiro</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 text-center  relative offer-2">
          <div className="md:mt-3">
            <h3 style={{ color: "#08c" }}> Método DHI Plus Gold</h3>
            <div className="card h-16rem mt-3">
              <div className="flex align-items-center justify-content-center">
                <div
                  className="flex align-items-center justify-content-center border-circle relative overflow-hidden"
                  style={{
                    background: "#e32486",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <span className="font-normal text-4xl text-0 z-3">
                    1.750€
                  </span>
                  <div
                    className="absolute border-circle-top z-1"
                    style={{
                      marginTop: "180px",
                      background: "#BD1E6F",
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                </div>
                <div
                  className="flex justify-content-center absolute mx-8 w-full h-20rem border-round"
                  style={{ marginTop: "410px" }}
                >
                  <div className="surface-0 font-semibold text-xl absolute z-3 p-3 text-center">
                    Oferta especial
                    <span className="block font-normal text-base mb-3">
                      Hasta 4.000 folículos
                    </span>
                    <div className="card text-left font-light text-base">
                      <span className="surface-300 px-2">
                        Procedimiento de Trasplante Capilar
                      </span>
                      <span className="px-2 border-1 border-300">
                        Traslados: aeropuerto - hotel - clínica y regreso
                        aeropuerto
                      </span>
                      <span className="surface-300 px-2">
                        3 noches de alojamiento en Hotel 4* estrellas con
                        desayuno
                      </span>
                      <span className="px-2 border-1 border-300">
                        Pre-anestesia sin dolor
                      </span>
                      <span className="surface-300 px-2">
                        Medicación Post-operatorio
                      </span>
                      <span className="px-2 border-1 border-300">
                        PRP (plasma rico en plaquetas)
                      </span>
                      <span className="surface-300 px-2">
                        Traductor de idioma
                      </span>
                      <span className="px-2 border-1 border-300">
                        <span className="font-semibold">* PACK + 100€</span>{" "}
                        (opcional) 3 noches de hotel 5* con desayuno
                      </span>
                      <Link to="/transplante-capilar/dhi-plus-gold" className="border-round-md  no-underline p-3 text-base text-center" style={{ background: "#08c", color: '#fff' }}>Saber más sobre el método DHI Plus Gold</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-center trasplante"
        style={{ padding: "0 30px", marginTop: "150px" }}
      >
        <Divider align="center">
          <h2 className="px-3" style={{ color: "#e75480" }}>
            Trasplante capilar
          </h2>
        </Divider>
        <div className="grid mx-0">
          <div className="col-12 lg:col-3 offer-3">
            <div className="card h-16rem mt-3">
              <div className="flex align-items-center justify-content-center">
                <div
                  className="flex align-items-center justify-content-center border-circle  overflow-hidden"
                  style={{
                    background: "#e32486",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <span className="font-normal text-4xl text-0 z-3">
                    2.600€
                  </span>
                  <div
                    className="absolute border-circle-top z-1"
                    style={{
                      marginTop: "140px",
                      background: "#BD1E6F",
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                </div>
                <div
                  className="flex justify-content-center absolute  w-full h-20rem border-round"
                  style={{ marginTop: "410px" }}
                >
                  <div className="surface-0 font-semibold text-xl absolute z-3 p-3 text-center">
                    Rinoplastia
                    <div className="card text-left font-light text-base">
                      <span className="surface-300 px-2">
                        Sencilla: 2.600 €
                      </span>
                      <span className="px-2 border-1 border-300">
                        Complicada: 3.100 €
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-3">
            <div className="card h-16rem mt-3">
              <div className="flex align-items-center justify-content-center">
                <div
                  className="flex align-items-center justify-content-center border-circle relative overflow-hidden"
                  style={{
                    background: "#e32486",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <span className="font-normal text-4xl text-0 z-3">
                    3.100€
                  </span>
                  <div
                    className="absolute border-circle-top z-1"
                    style={{
                      marginTop: "140px",
                      background: "#BD1E6F",
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                </div>
                <div
                  className="flex justify-content-center absolute  w-full h-20rem border-round"
                  style={{ marginTop: "410px" }}
                >
                  <div className="surface-0 font-semibold text-xl absolute z-3 p-3 text-center">
                    Aumento de pecho
                    <div className="card text-left font-light text-base">
                      <span className="surface-300 px-2">Normal: 3.100 €</span>
                      <span className="px-2 border-1 border-300">
                        Round anatomic: 3.600 €
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-3">
            <div className="card h-16rem mt-3">
              <div className="flex align-items-center justify-content-center">
                <div
                  className="flex align-items-center justify-content-center border-circle relative overflow-hidden"
                  style={{
                    background: "#e32486",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <span className="font-normal text-4xl text-0 z-3">
                    2.350€
                  </span>
                  <div
                    className="absolute border-circle-top z-1"
                    style={{
                      marginTop: "140px",
                      background: "#BD1E6F",
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                </div>
                <div
                  className="flex justify-content-center absolute  w-full h-20rem border-round"
                  style={{ marginTop: "410px" }}
                >
                  <div className="surface-0 font-semibold text-xl absolute z-3 p-3 text-center w-14rem">
                    Láser Lipolysis
                    <div className="card text-left font-light text-base">
                      <span className="surface-300 px-2">1 zona: 2.350 €</span>
                      <span className="px-2 border-1 border-300">
                        2 zonas: 2.500 €
                      </span>
                      <span className="surface-300 px-2">3 zonas: 2.800 €</span>
                      <span className="px-2 border-1 border-300">
                        4 zonas: 3.000 €
                      </span>
                      <span className="surface-300 px-2">5 zonas: 3.200€</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-3">
            <div
              className="card h-16rem mt-8 md:mt-3"
              style={{ marginTop: "80px" }}
            >
              <div className="flex align-items-center justify-content-center">
                <div
                  className="flex align-items-center justify-content-center border-circle relative overflow-hidden"
                  style={{
                    background: "#e32486",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <span className="font-normal text-4xl text-0 z-3">
                    3.100€
                  </span>
                  <div
                    className="absolute border-circle-top z-1"
                    style={{
                      marginTop: "140px",
                      background: "#BD1E6F",
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                </div>
                <div
                  className="flex justify-content-center absolute mx-8 w-full h-15rem border-round "
                  style={{ marginTop: "410px" }}
                >
                  <div className="surface-0 font-semibold text-xl absolute z-3 p-3 text-center w-15rem">
                    Abdominales Six Pack
                    <div className="card text-left font-light text-base">
                      <span className="surface-300 px-2">Estándar: 3.300</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-18rem border-round-md flex align-items-center justify-content-center">
            <div className="text-center text-0">
              <Divider align="center">
                <h2 className="px-3" style={{ color: "#e75480" }}>
                  Intervenciones en Turquía, acompañados en todo momento.
                </h2>
              </Divider>
              <p className="px-3 " style={{ color: "gray" }}>
                Sabemos lo importante que es sentirse acompañado. Por eso, en
                Estethica salimos juntos desde España y te acompañamos desde el
                principio hasta el final del proceso.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
}

export default Tarifas;
