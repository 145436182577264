import * as React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import Img from "../img/ESTETHICA.png";

export default function CardsImg() {
  return (
    <div
      className="CardsImg w-full text-center">
      <Divider
        align="center">
        <h3
          className="px-3"
          style={{ color: "#E75480" }}>
          ¿ Por qué elegirnos ?
        </h3>
      </Divider>
      <i>
        <h6 className="px-3" style={{ color: 'gray' }}>
          Especialistas en tratamientos de cirugía estética y trasplante
          capilar en Turquía
        </h6>
      </i>
      <div className="w-full text-center">
        <img
          className="w-full"
          src={Img}
          alt="cardsimg"
        />
      </div>

      <div className="w-full text-center"
        style={{ padding: "0 30px", color: 'gray' }}>
        <i className="px-3">
          <h6> Disponemos de un hospital y tres clínicas altamente preparadas y
            cualificadas para hacer cualquier intervención guardando todo tipo de
            garantías. Atención cuidadosa y personalizada centrada en dar el mejor
            servicio a cada uno de nuestros pacientes.
          </h6>
        </i>
      </div>
      <div className="flex flex-column md:flex-row justify-content-center md:justify-content-around">
        <div className="items my-2 md:my-4">
          <Card className="w-full md:w-20rem lg:w-30rem md:h-15rem text-center shadow-3">
            <ul
              className="flex flex-column align-items-center p-0"
              style={{ color: "#08c" }}
            >
              <li className="list-none text-center">
                <p>Equipo médico especializado</p>
              </li>
              <li className="list-none text-center">
                <p>Habitaciones de primera clase</p>
              </li>
              <li className="list-none text-center">
                <p>Unidades diseñadas para el trasplante capilar</p>
              </li>
              <li className="list-none text-center">
                <p>SPA y Centro Wellness</p>
              </li>
            </ul>
          </Card>
        </div>
        <div className="items my-2 md:my-4">
          <Card className="w-full md:w-20rem lg:w-30rem md:h-15rem text-center shadow-3">
            <ul
              className="flex flex-column align-items-center p-0"
              style={{ color: "#08c" }}
            >
              <li className="list-none text-center">
                <p>Hospital y clínicas propias</p>
              </li>
              <li className="list-none text-center">
                <p>Excelente ubicación</p>
              </li>
              <li className="list-none text-center">
                <p>Centro de referencia a nivel mundial</p>
              </li>
              <li className="list-none text-center">
                <p>Atención personalizada</p>
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
}
