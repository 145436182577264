import React from "react";
import { Card } from "primereact/card";

function LaserLipolysisPlusCardsBody() {
  return (
    <div className="LaserLipolysisPlusCardsBody" style={{ paddingTop: '60px' }}>
      <div className="card text-center w-full" style={{
        padding: "0 30px",
        color: "gray"
      }}>
        <h2
          className="p-3"
          style={{ color: "#e75480" }}>
          Aplicación del tratamiento
        </h2>
        <p
          className="px-3">
          <strong> Láser Lipolysis Plus puede utilizarse tanto en mujeres como en
            hombres (incluso en zonas anteriormente tratadas) para:</strong>
        </p>
        <p
          className="px-3">
          <strong>1: </strong> Eliminar depósitos de tejido adiposo no
          deseado y moldear el cuerpo.
        </p>
        <p
          className="px-3 text-center" >
          <strong>2: </strong>Estirar y reafirmar zonas en las que se
          observe flacidez.
        </p>
        <p
          className="px-3 text-center">
          <strong>3: </strong>Resolver los problemas de exceso de
          sudoración en la zona axilar.
        </p>

        <strong> <p className="px-3 text-center" style={{ color: "gray" }}>
          Garantiza resultados óptimos en zonas en las que el cliente no logra
          obtener los resultados deseados a pesar de cambiar los hábitos
          alimenticios y practicar deporte. Es altamente eficiente a la hora
          de eliminar flacidez localizada y puede utilizarse en:
        </p></strong>

        <p
          className="px-3">
          <i>Abdomen</i>
        </p>
        <p
          className="px-3">
          <i>Espalda</i>
        </p>
        <p
          className="px-3 ">
         <i> Cintura</i>
        </p>
        <p
          className="px-3" >
          <i>Rostro</i>
        </p>
        <p
          className="px-3" >
          <i>Barbilla y cuello</i>
        </p>
        <p
          className="px-3">
         <i> Brazos</i>
        </p>
        <p
          className="px-3" >
          <i>Piernas</i>
        </p>
        <p
          className="px-3">
          <i>Tobillos</i>
        </p>
        <p
          className="px-3" >
          <i>Ginecomastia</i>
        </p>
        <h2
          className="p-3"
          style={{ color: "#e75480" }}>
          Diferencias con el Láser Lipolítico Tradicional.
        </h2>
        <p
          className="px-3">
          El Láser Lipolysis Plus es una herramienta innovadora que ha
          revolucionado la lipoescultura y el moldeado corporal. También
          conocida como “liposucción asistida por láser”, utiliza una doble
          longitud de onda que proporciona excelentes resultados tanto en la
          reducción de volumen como en la reducción de flacidez.
        </p>
        <p
          className="px-3">
          <strong>
            Disuelve el tejido adiposo para conseguir reducir el volumen
            corporal:
          </strong>
          utilizamos la energía láser para disolver el tejido adiposo con
          un efecto duradero en el tiempo.
        </p>

        <p
          className="px-3">
          <strong>Estira y reafirma: </strong>
          El colágeno y la elastina componen los tejidos más importantes
          que proporcionan elasticidad a la piel. Estas sustancias actúan
          como un adhesivo que mantiene unidas las células de la piel. El
          descenso de la producción de colágeno y elastina es la causa de
          la flacidez.
        </p>

        <p
          className="px-3">
          <strong>Recuperación más rápida, </strong>
          menos tiempo en vendajes y una piel más tensa y firme.
        </p>
        <p
          className="px-3" >
          <strong>
            Los efectos de la Lipólisis Láser Plus perduran en el tiempo{" "}
          </strong>
          y son específicos para la destrucción de las células grasas.
        </p>
        <p className="p-3">
          <strong>  * ADVERTENCIA:</strong> Cada persona es única. Esto significa que la duración
          del tratamiento, la planificación y los resultados pueden ser
          diferentes para usted. La información que ve aquí es el promedio
          resultante de una amplia gama de muestras. Puede contactarnos para
          obtener información mas detallada a través del teléfono 649 633 448.
        </p>
      </div>
    </div>
  );
}

export default LaserLipolysisPlusCardsBody;
