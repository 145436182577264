import React, { useState } from 'react'
import EmailIcon from "@mui/icons-material/Email";
import { useFormik, Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownItem } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import { apiKudiExt } from '../../utils/axios.kudi';
import { Toast } from 'primereact/toast';
import { reCaptcha } from '../reCaptcha/ReCaptcha';
import { getListPrefixWorld, getMaxLenght, isValidPhone, getStrPhoneJoined } from './prefixPhoneWorld'
import { getListTratamientos } from '../../utils/FormUtils'
import '../../App.css';


export default function Form() {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setShowMessage] = useState(false);
  const [, setFormData] = useState({});
  const [send, setSend] = React.useState(false);
  const toast = React.useRef(null);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      apellido: '',
      prefix: '+1',
      tratamiento: 'Trasplate capilar con técnica DHI PlusGold',
      telefono: '',
      mensaje: '',
      accept: false
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Dato obligatorio.';
      }

      if (!data.email) {
        errors.email = 'Dato obligatorio.';
      }
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = 'Correo E.g. example@email.com';
      }

      if (!data.apellido) {
        errors.apellido = 'Dato obligatorio.';
      }
      if (!data.telefono) {
        errors.telefono = 'Dato obligatorio.';
      }
      else if (isValidPhone(formik.values.prefix, formik.values.telefono) !== true) {
        errors.telefono = isValidPhone(formik.values.prefix, formik.values.telefono);
      }

      if (!data.accept) {
        errors.accept = 'Debe aceptar los términos y condiciones.';
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      setShowMessage(true);
      handleLoading();
      apiKudiExt.post("/pre_lead_estethicas/ext", {
        "client": `${formik.values.name} ${formik.values.apellido}`,
        "phone": getStrPhoneJoined(formik.values.prefix, formik.values.telefono),
        "email": formik.values.email,
        "msg": + formik.values.tratamiento + ': ' + formik.values.mensaje,
      }).then(response => {
        setSend(true);
        toast.current.show({
          severity: 'success',
          summary: 'Envío exitoso',
          detail: 'Nos pondremos en contacto con usted lo antes posible',
          life: 3000
        });
        formik.resetForm();
      }).catch(error => {
        console.log(error);
        toast.current.show({
          severity: 'error',
          summary: 'Fallo en el envío',
          detail: 'No se puedo establecer conexión con el servidor. Intente nuevamente.',
          life: 3000
        });
        setLoading(false);
      }).finally(() => {
        setLoading(true)
      });
    }
  });

  const onSearch = (event) => {
    const filteredOptions = getListPrefixWorld()
    setOptions(filteredOptions);
  };
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };
  const isFormFieldValidApellido = (apellido) => !!(formik.touched[apellido] && formik.errors[apellido]);
  const getFormErrorMessageApellido = (apellido) => {
    return isFormFieldValid(apellido) && <small className="p-error">{formik.errors[apellido]}</small>;
  };
  const isFormFieldValidTelefono = (telefono) => !!(formik.touched[telefono] && formik.errors[telefono]);
  const getFormErrorMessageTelefono = (telefono) => {
    return isFormFieldValid(telefono) && <small className="p-error">{formik.errors[telefono]}</small>;
  };
  const isFormFieldValidEmail = (email) => !!(formik.touched[email] && formik.errors[email]);
  const getFormErrorMessageEmail = (email) => {
    return isFormFieldValid(email) && <small className="p-error">{formik.errors[email]}</small>;
  };
  const handleCaptcha = (value) => {
    setCaptcha(value);
  }
  const handleLoading = () => {
    if (formik.values.apellido !== ""
      && formik.values.name !== ""
      && formik.values.email !== ""
      && formik.values.telefono !== "")
      setLoading(false)
    return loading;
  };
  return (
    <React.Fragment>
      <div className="form-demo">
        <div className="flex align-items-center justify-content-center">
          <div className="cardForm ">
            <form
              onSubmit={formik.handleSubmit}
              className="p-fluid">
              <div className="text-start text-2xl"
                style={{ marginTop: '5px', marginBottom: '35px', paddingTop: '5px', paddingBottom: '5px', background: '#E75480', borderRadius: '6px' }}>
                <span style={{ paddingLeft: '15px', paddingRight: '10px', fontSize: '24px', color: '#FFF' }} >
                  <EmailIcon />
                </span>
                <span style={{ fontSize: '24px', color: '#FFF' }}>Solicita una valoración</span>
              </div>
              <div className="grid mx-0">
                <div className="col-12 md:col-6">
                  <div className="field" >
                    <span className="p-float-label">
                      <InputText
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })}
                        autoComplete='off'
                      />
                      <label
                        htmlFor="name"
                        className={classNames({ 'p-error': isFormFieldValid('name') })}>Nombre
                        <span style={{ color: '#e75480' }}>*</span></label>
                    </span>
                    {getFormErrorMessage('name')}
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="field">
                    <span className="p-float-label">
                      <InputText
                        id="apellido"
                        name="apellido"
                        value={formik.values.apellido}
                        autoComplete='off'
                        onChange={formik.handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValidApellido('apellido') })} />
                      <label htmlFor="apellido"
                        className={classNames({ 'p-error': isFormFieldValidApellido('apellido') })}>Apellidos
                        <span style={{ color: '#e75480' }}>*</span></label>
                    </span>
                    {getFormErrorMessageApellido('apellido')}
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="field flex align-items-center">
                    <Dropdown
                      value={formik.values.prefix}
                      options={getListPrefixWorld()}
                      onChange={formik.handleChange}
                      filter
                      filterPlaceholder="Buscar"
                      onFilter={onSearch}
                      // value={formik.values.prefix}
                      // options={getListPrefixWorld()}
                      appendTo={'self'}
                      optionValue='value'
                      optionLabel='value'
                      itemTemplate={opt => { return <div>{opt.label}</div> }}
                      id={"prefix"}
                      // onChange={formik.handleChange}
                      panelStyle={{ width: '335px' }}
                      style={{ borderRadius: '6px 0 0 6px', width: "8rem" }}
                    />
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-phone" />
                      <InputText
                        id="telefono"
                        name="telefono"
                        maxLength={getMaxLenght(formik.values.prefix)}
                        pattern="[0-9]+"
                        autoComplete='off'
                        value={formik.values.telefono}
                        onChange={formik.handleChange}
                        style={{ borderRadius: '0 6px 6px 0' }}
                      />
                      <label htmlFor="telefono"
                        className={classNames({ 'p-error': isFormFieldValidTelefono('telefono') })}>
                        <span>{`Teléfono (${formik.values.telefono.length}/${getMaxLenght(formik.values.prefix)})`}
                          <span style={{ color: '#e75480' }}>*</span></span>
                      </label>
                    </span>
                  </div>
                  {getFormErrorMessageTelefono('telefono')}
                </div>
                <div className="col-12 md:col-6">
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <InputText
                        id="email"
                        name="email"
                        autoComplete='off'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValidEmail('email') })} />
                      <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValidEmail('email') })}>Correo
                        <span style={{ color: '#e75480' }}>*</span></label>
                    </span>
                    {getFormErrorMessageEmail('email')}
                  </div>
                </div>
                <span className='mx-auto text-xl font-semibold pb-1'
                  style={{ color: '#E75480' }}>
                  Seleccione el tratamiento que desea*
                </span>
                <div className="col-12 text-center">
                  <div className="field flex align-items-center">
                    <Dropdown
                      value={formik.values.tratamiento}
                      options={getListTratamientos()}
                      appendTo={'self'}
                      id="tratamiento"
                      onChange={formik.handleChange}
                      style={{ borderRadius: '6px 6px 6px 6px', width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              {formik.values.tratamiento === 'Otros tratamientos' ?
                <div className="col-12">
                  <div className="field ">
                    <span className="p-float-label">
                      <InputTextarea
                        id="mensaje"
                        name="mensaje"
                        rows={3}
                        autoComplete='off'
                        value={formik.values.mensaje}
                        onChange={formik.handleChange} />
                      <label
                        htmlFor="mensaje"
                        className="pb-1">Mensaje</label>
                    </span>
                  </div>
                </div>
                :
                null}
              <div className='flex justify-content-center align-items-center'>
                <div className='contenedor w-19rem'>
                  <div className="field-checkbox mt-3">
                    <Checkbox
                      inputId="accept"
                      name="accept"
                      checked={formik.values.accept}
                      onChange={formik.handleChange}
                      className={classNames({ 'p-invalid': isFormFieldValid('accept') })} />
                    <label htmlFor="accept"
                      className={classNames({ 'p-error': isFormFieldValid('accept') })}>
                      <span>He leído y acepto las </span>
                      <Link to='/politica-privacidad'
                        className='underline' style={{ color: '#e75480' }}>Política de privacidad</Link>
                      <svg stroke="currentColor"
                        className="ml-1 mb-2"
                        style={{ color: '#e75480' }}
                        fill="none" strokeWidth="0"
                        viewBox="0 0 15 15"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3 2C2.44772 2 2 2.44772 2 3V12C2 12.5523 2.44772 13
                     3 13H12C12.5523 13 13 12.5523 13 12V8.5C13 8.22386 12.7761
                      8 12.5 8C12.2239 8 12 8.22386 12 8.5V12H3V3L6.5 3C6.77614
                       3 7 2.77614 7 2.5C7 2.22386 6.77614 2 6.5 2H3ZM12.8536 
                       2.14645C12.9015 2.19439 12.9377 2.24964 12.9621 2.30861C12.9861
                        2.36669 12.9996 2.4303 13 2.497L13 2.5V2.50049V5.5C13 5.77614 12.7761
                         6 12.5 6C12.2239 6 12 5.77614 12 5.5V3.70711L6.85355 8.85355C6.65829
                          9.04882 6.34171 9.04882 6.14645 8.85355C5.95118 8.65829 5.95118
                           8.34171 6.14645 8.14645L11.2929 3H9.5C9.22386 3 9 2.77614 9 2.5C9
                            2.22386 9.22386 2 9.5 2H12.4999H12.5C12.5678 2 12.6324 2.01349
                             12.6914 2.03794C12.7504 2.06234 12.8056 2.09851 12.8536 2.14645Z"
                          fill="currentColor">
                        </path>
                      </svg></label>
                  </div>
                  <span className='text-900'>*Todas nuestras intervenciones se realizan en Turquía*</span>
                </div>
              </div>
              <div className="flex justify-content-center align-items-center my-3">
                <ReCAPTCHA
                  sitekey={reCaptcha}
                  onChange={handleCaptcha}
                />
              </div>
              <div
                className="flex justify-content-center align-items-center my-3">
                <Button
                  type="submit"
                  onChange={handleLoading}
                  label={loading ? "Enviar" : <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="5" animationDuration=".5s" />}
                  className="enviar"
                  disabled={captcha === null || formik.values.accept === false
                    || formik.values.apellido === '' || formik.values.name === ''
                    || formik.values.email === '' || formik.values.telefono === ''}
                  style={{ backgroundColor: '#E75480', borderColor: '#E75480' }} />
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        send ?
          <Toast ref={toast} /> : <Toast ref={toast} />
      }

    </React.Fragment >
  );
}

