import axios from "axios";

const instanceExt = axios.create({
    baseURL: process.env.REACT_APP_API_HOSTK_API,
});

instanceExt.interceptors.request.use(
    (config) => {
        config.headers = {
            "Kkey": process.env.REACT_APP_API_KEYK,
            "Content-Type": "application/json",
        };
        config.params = {
            ...config.params
        }
        return { ...config };
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const apiKudiExt = instanceExt;