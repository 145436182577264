import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/1.jpg";
import Img1 from "./img/2.jpg";
import Img2 from "./img/3.jpg";
import { Divider } from "primereact/divider";
function AboutUsCardsImg() {
  return (
    <div className="AboutUsCardsImg">
      <div className="card text-center w-full"
        style={{
          padding: "0 30px",
          color: "#E75480",
        }}
      >
        <Divider
          align="center">
          <h2
            className="px-3"
            style={{ color: "#E75480" }}>
            Nuestra infraestructura
          </h2>
        </Divider>
        <div className="grid mx-0">
          <div className="col-12 md:col-4">
            <h4 className="px-3" style={{ color: "#E75480" }}>
              Clínicas Estethica
            </h4>
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img}
              alt="cardsimg"
            />
          </div>
          <div className="col-12 md:col-4">
            <h4 className="px-3" style={{ color: "#E75480" }}>
              Nuestro equipo
            </h4>
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img1}
              alt="cardsimg"
            />
          </div>
          <div className="col-12 md:col-4">
            <h4 className="px-3" style={{ color: "#E75480" }}>
              Silence Istambul
            </h4>
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img2}
              alt="cardsimg"
            />
          </div>
        </div>
      </div>
    </div >
  );
}

export default AboutUsCardsImg;
