import React from "react";
import { Card } from "primereact/card";

function SixPackCardsBody() {
  return (
    <div className="SixPackCardsBody">
      <div className="card w-full text-center" style={{
        padding: "0 30px",
        color: "gray"
      }}>
        <h2 className="p-3" style={{ color: "#e75480" }}>
          ¿Por qué realizarse cirugía Six Pack?
        </h2>
        <p className="px-3">
          <strong>La intervención de cirugía estética muscular para lucir Six Pack
            definido, presenta diferentes ventajas:</strong>
        </p>
        <p
          className="px-3">
          Es muy práctica y de corta duración.
        </p>
        <p
          className="px-3">
          Se realiza mediante pequeñas incisiones de 4-5 mm que coinciden
          con los pliegues del cuerpo.
        </p>

        <p
          className="px-3">
          Por ese motivo la cicatriz es casi imperceptible.
        </p>
        <p
          className="px-3 ">
          Es indolora.
        </p>
        <p
          className="px-3">
          El tiempo de recuperación es corto.
        </p>
        <p
          className="px-3">
          Se obtiene un cuerpo dinámico, en forma y musculado
          inmediatamente después de la operación.
        </p>
        <p
          className="px-3" >
          La cirugía estética muscular proporciona resultados extraordinarios,
          especialmente para aquellos que han intentado, hasta ahora sin
          suerte, conseguir un cuerpo de ensueño. Disfrutarás de resultados
          duraderos y sin esfuerzo. La nueva solución para hombres y mujeres
          que sueñan con tener un cuerpo perfecto.
        </p>
        <h2
          className="p-3"
          style={{ color: "#e75480" }}>
          Antes de la cirugía...
        </h2>
        <p
          className="px-3">
          <strong>Antes de realizar la operación, hay que conocer:</strong>
        </p>
        <p
          className="px-3 ">
          <strong>Edad mínima: </strong>
          Hombres y mujeres jóvenes y de mediana edad que desean tener un
          aspecto atlético (Consultar).
        </p>
        <p
          className="px-3">
          <strong>Requisitos: </strong>
          Personas sin obesidad general, pero con un exceso de grasa
          localizada.
        </p>
        <p
          className="px-3" >
          <strong>¿ Por qué ?: </strong>
          Aquellos que no tienen tiempo de realizar ejercicio intenso pero
          desean conseguir un cuerpo musculoso rápidamente Personas con
          dificultades para desarrollar y hacer visible su tejido
          muscular.
        </p>
        <p
          className="px-3" >
          <strong>Tipo de anestesia y duración de la operación: </strong>
          La cirugía de Six Pack se realiza bajo anestesia general y la
          duración depende del área o áreas en las que se realice la
          intervención, pero suele durar de 1 a 2 horas.
        </p>
        <h2 className="p-3 text-center" style={{ color: "#e75480" }}>
          En el postoperatorio...
        </h2>
        <p
          className="px-3">
          <strong>Una vez realizada la cirugía, es muy importante realizar un buen
            postoperatorio siguiendo todas las pautas:</strong>
        </p>
        <p
          className="px-3">
          En la mayoría de los casos se recomienda llevar un corsé ligero
          para sujetar la zona.
        </p>
        <p
          className="px-3">
          La hinchazón es la respuesta natural del cuerpo después de una
          intervención quirúrgica.
        </p>
        <p
          className="px-3 ">
          Las incisiones milimétricas se difuminan con el tiempo y se
          adaptan al color natural de la piel.
        </p>
        <p
          className="px-3">
          Las cicatrices no son visible y los resultados son óptimos.
        </p>
        <p
          className="px-3">
          Hacer ejercicio de manera regular después de la cirugía, así
          como seguir un programa de alimentación saludable, ayudará a que
          los resultados perduren.
        </p>
        <p
          className="px-3">
          Si engorda más de 10 kg, el resultado de la operación se verá
          mermado. Recomendamos a los pacientes que controlen su peso.
        </p>
        <p
          className="px-3">
          Podrá realizar actividades deportivas suaves 3 semanas después
          de la operación y podrá realizar ejercicio físico intenso 2
          meses después.
        </p>
        <p className="p-3 ">
          <strong> * ADVERTENCIA:</strong> Cada persona es única. Esto significa que la duración
          del tratamiento, la planificación y los resultados pueden ser
          diferentes para usted. La información que ve aquí es el promedio
          resultante de una amplia gama de muestras. Puede contactarnos para
          obtener información mas detallada a través del teléfono 649 633 448.
        </p>
      </div>
    </div>
  );
}

export default SixPackCardsBody;
