import React from "react";
import { Card } from "primereact/card";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/Building.jpg";
import Img1 from "./img/baskan.jpg";
import { Divider } from "primereact/divider";

function AboutUsCardsImg() {
  return (
    <div className="AboutUsCardsImg" style={{ paddingTop: '60px' }}>
      <div className="card">
        <div
          style={{
            maxWidth: "100%",
            textAlign: "center",
            padding: "30px",
            color: "#e75480",
          }}
        >
          <Divider
            align="center">
            <h2
              className="p-3" style={{ color: "#e75480" }}>
              ¿ Quiénes somos ?
            </h2>
          </Divider>

          <h3 className="p-3">Ethica Health Group</h3>
          <p className="px-3" style={{ color: "gray" }}>
            Ethica Health Group es un grupo de clínicas que cuenta con una larga
            y afianzada trayectoria en el mundo de los trasplantes capilares y
            la cirugía estética. Siendo un referente a nivel mundial, cuenta con
            el equipo de estética médica más moderno e integral de Turquía.
            Dispone de seis clínicas en Estambul y realiza cualquier tipo de
            cirugía plástica siendo especialistas en el trasplante capilar.
          </p>
          <h3 className="p-3">Estethica España</h3>
          <p className="px-3" style={{ color: "gray" }}>
            Debido a la alta demanda en España de cirugía plástica de calidad y
            trasplantes capilares a un precio asequible, Ethica Health Group ha
            decidido implantarse en territorio español para ofrecer los mejores
            servicios con la mejor relación de calidad-precio del mercado.
          </p>
          <div className="grid mx-0">
            <div className="col-12 md:col-6">
              <img className="w-full max-h-30rem"
                style={{ padding: "30px" }}
                src={Img}
                alt="cardsimg"
              />
            </div>
            <div className="col-12 md:col-6">
              <img className="max-w-30rem max-h-30rem"
                style={{ padding: "30px" }}
                src={Img1}
                alt="cardsimg"
              />
              <p className="px-3" style={{ color: "gray" }}>
                <i>“El objetivo del Ethica Health Group es ser respetuoso con la
                  salud, aplicar el diagnóstico correcto y los mejores
                  tratamientos con médicos especialistas para poder restablecer
                  la salud de los pacientes sin olvidar la ética médica
                  necesaria”.</i>
              </p>
              <p className="px-3" style={{ color: "gray" }}>
                <strong>Dr. Murat Akdoğan</strong>
              </p>
              <p className="px-3" style={{ color: "gray" }}>
                <strong> Presidente de Ethica Health Group</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsCardsImg;
