import React from "react";
import { Card } from "primereact/card";
import DiamondIcon from "@mui/icons-material/Diamond";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import VerifiedIcon from "@mui/icons-material/Verified";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
export default function Cards() {
    return (
        <div className="Cards">
            <div className="card  w-full text-center"
                style={{ padding: '30px' }}>
                <Divider
                    align="center">
                    <h3 className="p-3"
                        style={{ color: '#E75480' }}>Especialistas en trasplante capilar en Turquía con las mejores garantías
                    </h3>
                </Divider>
                <div className="grid mx-0 my-3">
                    <div className="col-12 md:col-3">
                        <Card className=" text-center w-full lg:h-20rem md:h-30rem h-auto "
                            style={{
                                color: "#0076bf",
                            }}
                        >
                            <h5 className="text-lg text-center" style={{ color: '#E75480' }}
                            >Técnica Percutanea De Zafiro</h5>
                            <div className="flex justify-content-center py-3">
                                <DiamondIcon style={{ color: "#0076bf", fontSize: "2rem" }} />
                            </div>
                            <p style={{ color: "gray" }}>
                                El Grupo Estethica realiza trasplante capilar en Turquía con las técnicas más modernas e innovadoras del mundo
                            </p>
                            <Link to="/transplante-capilar/fue-con-zafiro" style={{ color: '#e75480' }}>Más Info <KeyboardDoubleArrowRight style={{ fontSize: "1.2rem" }} /></Link>
                        </Card>
                    </div>
                    <div className="col-12 md:col-3">
                        <Card className=" text-center w-full lg:h-20rem md:h-30rem h-auto "
                            style={{
                                color: "#0076bf",
                            }}
                        >
                            <h5 className="text-lg text-center" style={{ color: '#E75480' }}
                            >Hospital Y Clínicas Propias</h5>
                            <div className="flex justify-content-center py-3">
                                <LocalHospitalIcon style={{ color: "#0076bf", fontSize: "2rem" }} />
                            </div>
                            <p style={{ color: "gray" }}>
                                En nuestros centros realizamos trasplantes de cabello, bigote y cejas empleando la tecnología más puntera del mercado.
                            </p>
                            <Link to="/quienes-somos" style={{ color: '#e75480' }}>Más Info <KeyboardDoubleArrowRight style={{ fontSize: "1.2rem" }} /></Link>
                        </Card>
                    </div>
                    <div className="col-12 md:col-3">
                        <Card className=" text-center w-full lg:h-20rem md:h-30rem h-auto "
                            style={{
                                color: "#0076bf",
                            }}
                        >
                            <h5 className="text-lg text-center" style={{ color: '#E75480' }}
                            >Tarifas Competentes</h5>
                            <div className="flex justify-content-center py-3">
                                <VerifiedIcon style={{ color: "#0076bf", fontSize: "2rem" }} />
                            </div>
                            <p style={{ color: "gray" }}>
                                Disponemos de un hospital y varias clínicas propias que cuentan con los mejores equipos y personal altamente cualificado.
                            </p>
                            <Link to="/tarifas" style={{ color: '#e75480' }}>Más Info <KeyboardDoubleArrowRight style={{ fontSize: "1.2rem" }} /></Link>
                        </Card>
                    </div>
                    <div className="col-12 md:col-3">
                        <Card className=" text-center w-full lg:h-20rem md:h-30rem h-auto "
                            style={{
                                color: "#0076bf",
                            }}
                        >
                            <h5 className="text-lg text-center" style={{ color: '#E75480' }}
                            >Atención Personalizada</h5>
                            <div className="flex justify-content-center py-3">
                                <PeopleAltIcon style={{ color: "#0076bf", fontSize: "2rem" }} />
                            </div>
                            <p style={{ color: "gray" }}>
                                Contamos con las mejores ofertas y tarifas del mercado incluyendo siempre las mejores garantías para ofrecer el mejor resultado.
                            </p>
                            <Link to="/blog/en-desarrollo" style={{ color: '#e75480' }}>Más Info <KeyboardDoubleArrowRight style={{ fontSize: "1.2rem" }} /></Link>
                        </Card>
                    </div>
                </div>
                <h3 className="text-center px-3" style={{ color: '#E75480' }}
                >Acompañamos y atendemos a nuestros pacientes durante todo el proceso de la operación y su posterior recuperación.
                </h3>
            </div>
        </div>
    );
}
