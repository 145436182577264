import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/1.jpg";
import Img1 from "./img/2.jpg";
import Img2 from "./img/3.jpg";
import Img3 from "./img/4.jpg";
import Img4 from "./img/5.jpg";
import Img5 from "./img/6.jpg";
import Img6 from "./img/7.jpg";
import Img7 from "./img/8.jpg";
import Img8 from "./img/9.jpg";
import Img9 from "./img/10.jpg";
import Img10 from "./img/11.jpg";
import Img11 from "./img/12.jpg";
import Img12 from "./img/13.jpg";
import Img13 from "./img/14.jpg";
import Img14 from "./img/15.jpg";
import { FaPhone } from "react-icons/fa";
import { Divider } from "primereact/divider";
import { padding } from "@mui/system";

function BeforeAfterCardsImg() {
  return (
    <div className="BeforeAfterCardsImg" style={{ paddingTop: "60px" }}>
      <div className="card w-full text-center"
        style={{
          color: "#e75480",
          padding:'30px'
        }}
      >
        <Divider align="center">
          <h2 className="px-3">
            Trasplante capilar en Turía
          </h2>
        </Divider>
        <div className="grid mx-0">
          <div className="col-12 md:col-4">
            <CardMedia
              sx={{ width: "100%" }}
              component="img"
              image={Img}
              alt="cardsimg"
              height={"280px"}
            />
          </div>
          <div className="col-12 md:col-4">
            <CardMedia
              sx={{ width: "100%" }}
              component="img"
              image={Img1}
              alt="cardsimg"
              height={"280px"}
            />
          </div>
          <div className="col-12 md:col-4">
            <CardMedia
              sx={{ width: "100%" }}
              component="img"
              image={Img2}
              alt="cardsimg"
              height={"280px"}
            />
          </div>
        </div>
        <div className="text-center w-full"
          style={{
            color: "#e75480",
          }}
        >
          <Divider align="center">
            <h2 className="m-0 p-3">Rinoplastia en Turquía</h2>
          </Divider>
          <div className="grid mx-0">
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                image={Img3}
                alt="cardsimg"
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                image={Img4}
                alt="cardsimg"
              // height={'280px'}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                image={Img5}
                alt="cardsimg"
              // height={'280px'}
              />
            </div>
          </div>
        </div>

        <div className="w-full text-center"
          style={{
            color: "#e75480",
          }}
        >
          <h2 className="m-0 p-3">Operación de pecho en Turquía</h2>
          <div className="grid mx-0">
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                image={Img6}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                image={Img7}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                image={Img8}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
          </div>
        </div>
        <div className="w-full text-center"
          style={{
            color: "#e75480",
          }}
        >
          <Divider align="center">
            <h2 className="m-0 p-3">Tratamiento Six Pack en Turquía</h2>
          </Divider>
          <div className="grid mx-0">
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                padding="30px"
                image={Img9}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                padding="30px"
                image={Img10}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                padding="30px"
                image={Img11}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
          </div>
        </div>
        <div className="w-full text-center"
          style={{
            color: "#e75480",
          }}
        >
           <Divider align="center">
           <h2 className="m-0 p-3">Láser Lipolysis Plus en Turquía</h2>
           </Divider>
          <div className="grid mx-0">
            <div className="col-12 md:col-4 flex">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                padding="30px"
                image={Img12}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                padding="30px"
                image={Img13}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
            <div className="col-12 md:col-4">
              <CardMedia
                sx={{ width: "100%" }}
                component="img"
                padding="30px"
                image={Img14}
                alt="cardsimg"
                height={"280px"}
              />
            </div>
          </div>
        </div>
        <div className="w-full text-center"
          style={{
            padding: "0 30px",
            color: "gray",
          }}
        >
          <p className="p-3">
            <strong> * ADVERTENCIA:</strong> Cada persona es única. Esto significa que los
            resultados pueden ser diferentes para usted. Puede contactarnos para
            obtener información mas detallada a través del <br></br>
            <FaPhone className="text-600 mr-1" />
            Teléfono: 649 633 448.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BeforeAfterCardsImg;
