import React from "react";
import Navbar from "../Homepage/NavBar";
import PageFooter from "../Homepage/PageFooter";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import estethica from "../img/logo-estethica@2x.png";
export default function PrivacyPolicies() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div
        className="flex align-items-center justify-content-center w-full "
        style={{
          paddingTop: "60px", color: "gray",
        }}
      >
        <div className="card w-full text-center">
          <div
            style={{
              color: "#e75480",
              padding: "0 30px",
            }}
          >
            <Divider align="center">
              <img src={estethica} className="p-3" alt="estethica.icon" style={{ width: "17rem" }} />
            </Divider>
          </div>
          <h3 className="p-3" style={{ color: "#08c" }}>
            Mapa del sitio
          </h3>
          <ul className="mx-auto" style={{ color: "gray" }}>
            <div className="grid mx-0 px-3">
              <div className="col-12 md:col-6 md:px-8">
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/'
                    className="no-underline"
                    id="rutas">Inicio</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/transplante-capilar/fue-con-zafiro'
                    className="no-underline"
                    id="rutas">Fue con Zafiro</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/rinoplastia'
                    className="no-underline"
                    id="rutas">Rinoplastia</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/estetica-mamaria'
                    className="no-underline"
                    id="rutas">Estética mamaria</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/six-pack'
                    className="no-underline"
                    id="rutas">Six Pack</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/laser-lipolysis-plus'
                    className="no-underline"
                    id="rutas">Láser Lipolysis</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/restauracion-clinica'
                    className="no-underline"
                    id="rutas">Clínica de Restauración y Salud del Cabello</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/dermatologia'
                    className="no-underline"
                    id="rutas">Dermatología y estética médica</Link>
                </li>
              </div>
              <div className="col-12 md:col-6 md:px-8 ">
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/clinica-salud-oral'
                    className="no-underline"
                    id="rutas">Clínica de salud oral y dental</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/acupuntura'
                    className="no-underline"
                    id="rutas">Acupuntura</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tratamientos/venas-varicosas'
                    className="no-underline"
                    id="rutas">Venas varicosas</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/tarifas'
                    className="no-underline"
                    id="rutas">Tarifas</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/antes-despues'
                    className="no-underline"
                    id="rutas">Antes / Después</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/quienes-somos'
                    className="no-underline"
                    id="rutas">¿Quiénes somos?</Link>
                </li>
                <li className="list-decimal text-left"
                  id="rutas">
                  <Link to='/equipo-medico'
                    className="no-underline"
                    id="rutas">Equipo médico</Link>
                </li>
              </div>
            </div>
          </ul>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}
