import React from "react";
import Navbar from "../../../Homepage/NavBar";
import RhinoplastyCardsImg from "./RhinoplastyCardsImg";
import RhinoplastyCards from "./RhinoplastyCards";
import RhinoplastyCardsBody from "./RhinoplastyCardsBody";
import PageFooter from "../../../Homepage/PageFooter";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../../App.css";

function Rhinoplasty() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <progress max={100} value={0}></progress>
      <Navbar />
      <RhinoplastyCardsImg style={{ paddingTop: '40px' }} />
      <RhinoplastyCards />
      <RhinoplastyCardsBody />
      <PageFooter />
    </div>
  );
}

export default Rhinoplasty;