import React from "react";
import { Card } from "primereact/card";

function DermatologyCardsBody() {
  return (
    <div className="DermatologyCardsBody">
      <div className="card w-full text-center" style={{
        padding: "30px",
      }}>
        <div
          className="accordion accordion-flush px-3"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                style={{ color: "#e75480" }}
              >
                Lifting facial sin operación con correa de bloqueo
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Las deformaciones porcentuales debidas a la edad pueden
                  corregirse con la cirugía de estiramiento facial, pero se
                  han intentado desarrollar muchas alternativas para pacientes
                  que no desean ser operados.
                </p>
                <p style={{ color: "gray" }}>
                  Estethica trabaja con la técnica del “bloqueo de suspensión”
                  para estos casos.
                </p>
                <p style={{ color: "gray" }}>
                  La técnica “bloqueo de suspensión”, que se aplica con hilos
                  especiales flexibles, sin rotura y de fusión, es una nueva
                  técnica en faceless sin cirugía, que permite obtener
                  resultados agradables en la caída y arrugas de la cara y el
                  cuello. Es compatible con los movimientos de imitación y no
                  da lugar a un porcentaje de degradación.
                </p>
                <p style={{ color: "gray" }}>
                  Hace que el óvalo facial sea más distintivo Levanta las
                  cejas.
                </p>
                <p style={{ color: "gray" }}>
                  La línea de la mandíbula y el cuello caído Proporciona
                  resultados similares a las operaciones de cirugía estética.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                style={{ color: "#e75480" }}
              >
                Relleno
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Los rellenos se utilizan para aumentar el volumen de
                  cualquier estructura (cara, espalda, nariz, etc.) en el
                  cuerpo, rellenar las arrugas profundas y reponer el labio.
                  La permanencia de los rellenos varía según el producto
                  utilizado, con un promedio de 6-12 meses (4-6 meses en los
                  labios). Está disponible en relleno con un material
                  sintético de mayor duración.
                </p>
                <p style={{ color: "gray" }}>
                  Los rellenos se clasifican en 3 categorías:
                </p>
                <p style={{ color: "gray" }}>
                  No permanente (menos de 1 año)
                </p>
                <p style={{ color: "gray" }}>Semi permanente (1-2 años)</p>
                <p style={{ color: "gray" }}>
                  Son permanentes (más de 2 años)
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
                style={{ color: "#e75480" }}
              >
                Cuidado de la piel
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Estethica lleva a cabo tratamientos focalizados importados
                  de España a cargo de médicos experimentados y materiales y
                  medicamentos que cumplen con las licencias y normativas que
                  se exigen para estos casos.
                </p>
                <p style={{ color: "gray" }}>Tratamientos:</p>
                <p style={{ color: "gray" }}>
                  Clásico Cuidado de la piel Tratamiento antiedad Cuidado del
                  colágeno Gold Care
                </p>
                <p style={{ color: "gray" }}>
                  Tratamiento de mancha Tratamiento del acné Peeling químico
                  Peeling de carbón
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
                style={{ color: "#e75480" }}
              >
                Depilación láser
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  La depilación láser funciona según el principio de la
                  fototermólisis selectiva. En la depilación con láser, una
                  luz única se transmite intensamente a través de pelos de
                  colores, y el calor se concentra en la raíz del pelo y se
                  traduce en el folículo capilar mediante la destrucción, y el
                  cabello se destruye durante un tiempo prolongado. Con la
                  depilación láser, se puede obtener un resultado permanente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default DermatologyCardsBody;
