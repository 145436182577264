import React from "react";
import { Divider } from "primereact/divider";
function DHIPlusGoldVideo() {
  return (
    <div className="DHIPlusGoldVideo" style={{ paddingTop: '80px' }}>
      <div className="card text-center w-full" style={{
        padding: "0 30px",
        color: "#e75480",
      }}>
        <Divider align="center"><h2 className="p-3">Trasplante capilar técnica DHI Plus Gold</h2></Divider>
        <p className="px-3" style={{ color: "gray" }}>
          La técnica DHI Plus Gold de trasplante capilar en Turquía está hecha
          y desarrollada por la unidad de I + D de Estethica con una aguja con
          punta de oro, resultado de 12 meses de investigación y desarrollo .
        </p>
        <p className="px-3" style={{ color: "gray" }}>
          La técnica DHI Plus Gold proporciona una apariencia más intensa en
          el trasplante de cabello, proteger la imagen actual y permite que el
          paciente vuelva a la vida diaria rápidamente..
        </p>
      </div>
      <div className="flex align-items-center justify-content-center w-full py-3">
        <iframe
          width={'100%'}
          src="https://www.youtube.com/embed/2crxp-gWgQY"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            aspectRatio: '16 / 9',
            padding: '0 30px',
            maxHeight:'80vh'
          }}
        ></iframe>
      </div>
    </div>
  );
}

export default DHIPlusGoldVideo;
