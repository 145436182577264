import React from "react";
import { Card } from "primereact/card";

function VaricoseVeinsCardsBody() {
  return (
    <div className="VaricoseVeinsCardsBody" >
      <div className="card w-full text-center"
        style={{
          padding: "30px",
        }}
      >
        <div
          className="accordion accordion-flush px-3"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                style={{ color: "#e75480" }}
              >
                Venas Varicosas
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  Debido a que las piernas están ubicadas en la parte inferior
                  de nuestro cuerpo, es más probable que se encharquen debido
                  a fugas. Por esta razón, las venas varicosas ocurren
                  principalmente y con mayor frecuencia en las heces. El
                  diagnóstico de venas varicosas se realiza primero mediante
                  un examen detallado por un médico especializado en cirugía
                  cardiovascular. Luego, hoy se realiza la “ecografía doppler”
                  coloreada, que es el estándar de oro más comúnmente
                  utilizado en el diagnóstico de las venas varicosas. En este
                  método, a través de las ondas de sonido, se determina la
                  estructura de las venas, ya sea que haya coagulación,
                  velocidad y dirección del flujo sanguíneo. La ecografía
                  Doppler no tiene ningún peligro, no contiene rayos X, no
                  daña el cuerpo y no causa dolor. Ecografía Doppler; la causa
                  real de la enfermedad, si la operación es necesaria, qué
                  tipo de operación es apropiada, dónde se realizará la
                  operación, la probabilidad de éxito del tratamiento, En
                  algunos casos especiales, también se puede usar el método de
                  imágenes radiológicas de los vasos por administración
                  intravenosa, llamado “venografía”.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                style={{ color: "#e75480" }}
              >
                Ablación endovenosa con láser en el tratamiento de las varices
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  El tratamiento se basa en un generador de láser que
                  transmite en una vena que conduce a heredar con energía se
                  forma fiberoptikkatet por el calor y el resultado de la
                  contracción sustancialmente de la vasoconstricción y cierre
                  creado por esta energía a la pared del vaso.
                </p>
                <p style={{ color: "gray" }}>
                  La tecnología endovenosa se ha usado cada vez más en los
                  últimos 5 años en el tratamiento de las venas varicosas.
                  Este procedimiento se puede usar fácilmente en pacientes que
                  siempre han necesitado tratamiento quirúrgico.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
                style={{ color: "#e75480" }}
              >
                Ablación con radiofrecuencias endovenosa en terapia variceal
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p style={{ color: "gray" }}>
                  La radiofrecuencia es uno de los métodos de tratamiento
                  mínimamente invasivo que se puede aplicar en la
                  insuficiencia venosa. La diferencia del método EVLA es el
                  tipo de energía utilizada para cerrar el buque. La vena se
                  contrae y se cierra como resultado del calor y la
                  contracción creados por la energía de radiofrecuencia
                  transmitida por el catéter colocado en la vena del paciente,
                  en la pared del vaso.
                </p>
                <p style={{ color: "gray" }}>
                  Esta aplicación está hecha para venas safenas de hasta 20
                  milímetros. El período de curación es muy corto.
                  Estéticamente tiene mucho éxito. La ablación por RF puede
                  realizarse con anestesia local o bajo anestesia general. El
                  paciente no necesita ser hospitalizado después de la
                  aplicación.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VaricoseVeinsCardsBody;
