import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/six-pack.png";
import { Divider } from "primereact/divider";

function SixPackCardsImg() {
  return (
    <div className="SixPackCardsImg" style={{ paddingTop: '60px' }}>
      <div className="card w-full text-center" style={{
        padding: "30px",
        color: "#e75480",
      }}>

        <h2 className="p-3">
          Six Pack</h2>
        <Divider
          align="center">
          <h3 className="px-3">
            Cirugía estética muscular
          </h3>
        </Divider>
        <p
          className="px-3"
          style={{ color: "gray" }}>
          Tener un vientre plano y abdominales marcados proyecta una aspecto
          sano y estético, reforzando la autoestima de la persona y reduciendo
          el miedo a mostrar el torso a los demás.
        </p>
        <p
          className="px-3"
          style={{ color: "gray" }}>
          La operación de cirugía estética muscular consiste en preservar o
          realzar el tejido en áreas de flexión muscular y eliminar el tejido
          adiposo en áreas musculares mediante la técnica de liposucción,
          consiguiendo músculos definidos sin emplear implantes externos y con
          las técnicas más innovadoras.
        </p>
      </div>
      <img
        className="w-full"
        src={Img}
        alt="cardsimg"
      />
    </div>
  );
}

export default SixPackCardsImg;
