import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/1.jpg";
import Img1 from "./img/2.jpg";
import Img2 from "./img/3.jpg";
import Img3 from "./img/4.jpg";
import Img4 from "./img/5.jpg";
import Img5 from "./img/6.jpg";
import Img6 from "./img/7.jpg";
import Img7 from "./img/8.jpg";
import Img8 from "./img/9.jpg";
import Img9 from "./img/10.jpg";
import Img10 from "./img/11.jpg";
import Img11 from "./img/12.jpg";
import Img12 from "./img/13.jpg";
import Img13 from "./img/14.jpg";
import Img14 from "./img/15.jpg";
import Img15 from "./img/16.jpg";
import Img16 from "./img/17.jpg";
import Img17 from "./img/18.jpg";
import Img18 from "./img/19.jpg";
import { Divider } from "primereact/divider";
function MedicalEquipmentCardsImg() {
  return (
    <div className="MedicalEquipmentCardsImg" style={{ paddingTop: '60px' }}>
      <div className="card w-full text-center"
        style={{
          padding: "30px",
          color: "#E75480",
        }}
      >
        <Divider
          align="center">
          <h2 className="p-3" style={{ color: "#E75480" }}>
            Equipo médico
          </h2>
        </Divider>
        <div className="grid">
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Aida BERENJIAN
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Dermatología
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img1}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Begüm AYTUĞLU
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Tratamiento dental
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img2}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Burak EFEOĞLU
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Cirugía plástica
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img3}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Emin GÜL
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Trasplante y tratamiento capilar
            </i>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img4}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Harun ALAKAYA
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Implante capilar
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img5}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Emre TAMBAY
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Dermatología
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img6}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Hakan SAVAŞ
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Obstetricia
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img7}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Kıvanç DEMİR
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Cirugía plástica
            </i>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img8}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Müge ADIL
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Anestesiología y reanimación
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img9}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Nilgün GÜRSOY
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Medicina interna y endocrinología
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img10}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Pınar DEMİRKAYA
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Dietética
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img11}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Ramazan GÜLER
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Cirugía estética
            </i>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img12}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Selin ŞAHİN
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Dietética
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img13}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Sinan ULUSOY
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Cirugía plástica
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img14}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Siyamek SALEKİ
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Dermatología
            </i>
          </div>
          <div className="col-12 md:col-3">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img15}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Tuğçe KAYA
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Odontología
            </i>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-4">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img16}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Uğur ÖZDEMİR
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Cirugía plástica
            </i>
          </div>
          <div className="col-12 md:col-4">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img17}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Yalçın BAYRAM
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Cirugía estética
            </i>
          </div>
          <div className="col-12 md:col-4">
            <CardMedia
              sx={{ width: "100%", padding: "30px", imageClassName: "p-3" }}
              component="img"
              padding="30px"
              image={Img18}
              alt="cardsimg"
            />
            <h4 className="px-3" style={{ color: "gray" }}>
              Muzaffer YILDIZ
            </h4>
            <i className="px-3" style={{ color: "#08c" }}>
              Implante capilar
            </i>
          </div>
        </div>
      </div>
    </div >
  );
}

export default MedicalEquipmentCardsImg;
