import React from "react";
import { Card } from "primereact/card";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";

function FUESapphireCards() {
  return (
    <div className="FUESapphireCards my-5">
      <div className="grid mx-0">
        <div className="col-12 md:col-3">
          <Card className="flex align-items-center justify-content-center text-center shadow-1 w-full"
            style={{
              padding: "0 30px",
              height: "12rem",
            }}
          >
            <span style={{ color: "#e75480" }}>
              Duración de la operación
            </span>
            <div className="flex justify-content-center py-3" style={{ color: "#0076bf" }}>
              <AccessTimeFilledIcon style={{ color: "#0076bf", fontSize: "2rem" }}/>
            </div>

            <p className="m-0 px-3" style={{ color: "gray" }}>
              6 ~ 8 horas
            </p>
          </Card>
        </div>
        <div className="col-12 md:col-3">
          <Card className="flex align-items-center justify-content-center text-center shadow-1 w-full"
            style={{
              padding: "0 30px",
              height: "12rem",
            }}
          >
            <span style={{ color: "#e75480" }}>
              Vuelta al trabajo
            </span>
            <div className="flex justify-content-center py-3" style={{ color: "#0076bf", fontSize: "large" }}>
              <HomeRepairServiceIcon style={{ color: "#0076bf", fontSize: "2rem" }}/>
            </div>

            <p className="m-0 px-3" style={{ color: "gray" }}>
              0 ~ 1 días
            </p>
          </Card>
        </div>
        <div className="col-12 md:col-3">
          <Card className="flex align-items-center justify-content-center text-center shadow-1 w-full"
            style={{
              padding: "0 30px",
              height: "12rem",
            }}
          >
            <span style={{ color: "#e75480" }}>
              Estancia en el Hospital
            </span>
            <div className="flex justify-content-center py-3" style={{ color: "#0076bf", fontSize: "large" }}>
              <LocalHospitalIcon style={{ color: "#0076bf", fontSize: "2rem" }}/>
            </div>

            <p className="m-0 px-3" style={{ color: "gray" }}>
              0 ~ 1 días
            </p>
          </Card>
        </div>
        <div className="col-12 md:col-3">
          <Card className="flex align-items-center justify-content-center text-center shadow-1 w-full"
            style={{
              padding: "0 30px",
              height: "12rem",
            }}
          >
            <span style={{ color: "#e75480" }}>
              Anestesia
            </span>
            <div className="flex justify-content-center py-3" style={{ color: "#0076bf", fontSize: "large" }}>
              <BloodtypeIcon style={{ color: "#0076bf", fontSize: "2rem" }}/>
            </div>

            <p className="m-0 px-3" style={{ color: "gray" }}>
              Anestesia local
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default FUESapphireCards;
