import React from "react";
import { Card } from "primereact/card";

function RhinoplastyCardsBody() {
  return (
    <div className="RhinoplastyCardsBody" style={{ color: "gray" }}>
      <div className="card text-center w-full"
        style={{
          padding: "0 30px"
        }}>
        <h2 className="p-3"
          style={{ color: "#e75480" }}>
          ¿ Por qué someterse a una rinoplastia ?
        </h2>
        <p className="px-3">
          <strong>Razones de salud: </strong>operación recomendada para aquellos que,
          debido a defectos genéticos o de nacimiento, varias enfermedades
          o golpes, tienen dificultad para respirar, tienen la nariz
          tapada o rota, tienen dificultad para hablar, roncan en voz alta
          o se quejan de oler o no pueden oler.
        </p>
        <p className="px-3">
          <strong>Razones estéticas:</strong> la operación de estética nasal (rinoplastia)
          es una intervención quirúrgica para crear una nariz agradable
          estéticamente, corrigiendo las zonas que le desagradan al
          paciente y, al mismo tiempo, corrigiendo las dificultades
          respiratorias a través de la nariz en caso de que las hubiera.
          Este procedimiento es uno de los más frecuentes. Los
          procedimientos que se pueden realizar incluyen:
        </p>
        <p className="px-3">
          <strong> La remodelación de puentes nasales altos y torceduras.</strong>
        </p>
        <p className="px-3">
          <strong> Levantamiento de puntas.</strong>
        </p>
        <p className="px-3">
          <strong>Reducción de las alas de la nariz.</strong>
        </p>
        <p className="px-3">
          <strong>Corrección de curvaturas en el tabique.</strong>
        </p>
        <h2 className="p-3"
          style={{ color: "#e75480" }}>
          Antes de la cirugía...
        </h2>
        <p>
          <strong>
            Explicar de forma clara y precisa a su médico qué tipo de
            nariz desea.
          </strong>
        </p>
        <p className="px-3">
          <strong>Edad mínima: </strong>
          En mujeres es de 17 años y a partir de los 18 años en el caso de
          los hombres.
        </p>
        <p className="px-3">
          <strong>Métodos de operación: </strong>
          Existen dos tipos de métodos, abierto y cerrado. El método a
          utilizar es determinado por su médico antes de la operación.
        </p>

        <p className="px-3">
          <strong>Tipo de anestesia y duración de la operación: </strong>
          La cirugía nasal se realiza bajo anestesia general. La duración
          del procedimiento varía entre 1 y 3 horas.
        </p>
        <p className="px-3">
          <strong>Período de recuperación: </strong>
          Pueden aparecer moretones e hinchazones alrededor de los ojos
          del paciente inmediatamente después de la operación debido al
          sangrado durante la operación y al tipo de piel del propio
          paciente. Toma entre 6 meses y 1 año para que la nariz sane
          completamente.
        </p>
        <h2 className="p-3" style={{ color: "#e75480" }}>
          En el postoperatorio...
        </h2>
        <p
          className="px-3">
          <strong>  Una vez realizada la cirugía, es muy importante realizar un buen
            postoperatorio siguiendo todas las pautas:</strong>
        </p>

        <p
          className="px-3">
          El escudo y el vendaje colocado en la nariz deben permanecer al
          menos una semana y no mojarse.
        </p>
        <p
          className="px-3">
          No debe bañarse y debe evitar los lugares húmedos.
        </p>
        <p
          className="px-3">
          La piel de la nariz debe limpiarse con una loción especial una
          vez retirado el vendaje.
        </p>
        <p
          className="px-3">
          Se deben evitar los deportes intensivos y el ejercicio físico.
        </p>
        <p
          className="px-3">
          Los dientes deben ser cepillados con mucho cuidado durante las
          primeras 2 semanas.
        </p>
        <p
          className="px-3">
          Se deben evitar los movimientos faciales extremos (gestos)
          durante una semana.
        </p>
        <p
          className="px-3">
          Las lentes de contacto no deben usarse durante 5 días.
        </p>
        <p
          className="px-3">
          No tocar bruscamente la nariz, será sensible durante 4 semanas y
          protegerla de golpes.
        </p>

        <p className="px-3">
          Las gafas no deben usarse durante al menos 2 meses.
        </p>
        <p
          className="px-3">
          Evita la luz dañina de la luz solar y los solariums.
        </p>
        <p className="p-3" style={{ color: "gray" }}>
          <strong>* ADVERTENCIA:</strong> Cada persona es única. Esto significa que la duración
          del tratamiento, la planificación y los resultados pueden ser
          diferentes para usted. La información que ve aquí es el promedio
          resultante de una amplia gama de muestras. Puede contactarnos para
          obtener información mas detallada a través del teléfono 649 633 448.
        </p>
      </div>
    </div>
  );
}

export default RhinoplastyCardsBody;
