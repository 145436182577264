const selectItem = [
    {
        tratamiento: 'Trasplate capilar con técnica DHI PlusGold',
    },
    {
        tratamiento: 'Trasplate capilar con técnica FUE con Zafiro',
    },
    {
        tratamiento: 'Tratamiento de Rinosplastia',
    },
    {
        tratamiento: 'Tratamiento de Estética mamaria',
    },
    {
        tratamiento: 'Tratamiento de Six Pack',
    },
    {
        tratamiento: 'Tratamiento de Láser Lipólysis',
    },
    {
        tratamiento: 'Otros tratamientos',
    }
];

export function getListTratamientos() {
    return selectItem.map(ele => {
        return { label: `${ele.tratamiento}`, value: `${ele.tratamiento}` };
    });
}