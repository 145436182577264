import React from "react";
import Navbar from "../../../Homepage/NavBar";
import PageFooter from "../../../Homepage/PageFooter";
// import DHIPlusGoldCards from "./DHIPlusGoldCards";
import DHIPlusGoldVideo from "./DHIPlusGoldVideo";
import DHIPlusGoldCards1 from "./DHIPlusGoldCards1";
import DHIPlusGoldCardsBody from "./DHIPlusGoldCardsBody";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../../../../App.css";

function DHIPlusGold() {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to("progress", {
      value: 100,
      scrollTrigger: {
        scrub: 0.5,
      },
    });
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}, []);

  return (
    <div className="DHIPlusGold">
      <progress max={100} value={0}></progress>
      <Navbar />
      <DHIPlusGoldVideo/>
      <DHIPlusGoldCards1/>
      <DHIPlusGoldCardsBody/>
      <PageFooter />
    </div>
  );
}

export default DHIPlusGold;
