import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/2.png";
import { Divider } from "primereact/divider";


function RhinoplastyCardsImg() {
  return (
    <div className="RhinoplastyCardsImg"
      style={{ paddingTop: '60px' }}>
      <div className="card w-full text-center"
        style={{
          padding: "30px", color: "#e75480",
        }}>
        <h2 className="px-3">
          Rinoplastia en Turquía
        </h2>
        <Divider align="center">
          <h3 className="px-3">
            Estética nasal
          </h3>
        </Divider>
        <p className="px-3"
          style={{ color: "gray" }}>
          Toda persona quiere tener el rostro y el cuerpo más hermoso y por
          ello, la estética nasal es una de las operaciones estéticas faciales
          más consideradas y preferidas.
        </p>
        <p className="px-3"
          style={{ color: "gray" }}>
          La operación estética nasal, conocida como rinoplastia en el mundo
          médico, es la remodelación de la nariz. Se pueden realizar
          procedimientos como reducciones nasales, ampliaciones, levantamiento
          de puntas y extracción del hueso nasal (desviación).
        </p>
      </div>
      <img className="w-full py-3"
        src={Img}
        alt="cardsimg"
      />
    </div>
  );
}

export default RhinoplastyCardsImg;
