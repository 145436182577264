import React from "react";
import Img1 from "../img/1.png";
import Img2 from "../img/2.png";
import Img3 from "../img/9.jpeg";
import Img4 from "../img/7.png";
import Img5 from "../img/6.png";
import Img6 from "../img/5.png";
import Img7 from "../img/4.png";
import Img8 from "../img/3.png";
import Img9 from "../img/8.png";

export default function Carousel() {
  return (
    <div
      className="Carousel text-center w-full ">
      <h3
        className="text-center p-3"
        style={{ color: "#E75480"  }}
      >
        Otros tratamientos
      </h3>
      <div
        id="carouselExampleCaptions"
        className="carousel slide w-full mx-auto" 
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" style={{ color: "gray" }}>
            <h5>AUMENTO DE PECHO</h5>
            <img src={Img1} className="d-block w-full my-1" alt="Estética mamaria en Turquía con las técnicas más innovadoras" />
            <p>Estética mamaria en Turquía con las técnicas más innovadoras</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>RINOPLASTIA</h5>
            <img src={Img2} className="d-block w-full my-1" alt="Estética nasal en Turquía" />
            <p>Estética nasal en Turquía: reducción,ampliación...</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>LÁSER LIPOLYSIS PLUS</h5>
            <img src={Img3} className="d-block w-full my-1" alt="Reducción de volumen y modelado de cuerpo" />
            <p>Reducción de volumen y modelado de cuerpo</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>DERMATOLOGÍA</h5>
            <img src={Img4} className="d-block w-full my-1" alt="Lifting facial,relleno,cuidado de la piel" />
            <p>Lifting facial,relleno,cuidado de la piel...</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>ACUPUNTURA</h5>
            <img src={Img5} className="d-block w-full my-1" alt="Tratamientos de acupuntura" />
            <p>Tratamientos de acupuntura para una vida más cómoda</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>SALUD DEL CABELLO </h5>
            <img src={Img6} className="d-block w-full my-1" alt="Plantación de cabello, mesoterapia capilar, bigote" />

            <p>Plantación de cabello, mesoterapia capilar, bigote...</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>SALUD DENTAL</h5>
            <img src={Img7} className="d-block w-full my-1" alt="Implantes, blanqueamientos, ortodoncia, porcelana" />

            <p>Implantes, blanqueamientos, ortodoncia, porcelana...</p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>VENAS VARICOSAS</h5>
            <img src={Img8} className="d-block w-full my-1" alt="Eliminación de varices con láser" />
            <p>
              Eliminación de varices con láser, con radiofrecuencia
              endovenosa...
            </p>
          </div>
          <div className="carousel-item" style={{ color: "gray" }}>
            <h5>MEDICINA TRADICIONAL</h5>
            <img src={Img9} className="d-block w-full" alt="Medicina Ibn Sina, tratamiento de ventosas y con sanguijuelas" />
            <p>Medicina Ibn Sina, tratamiento de ventosas y con sanguijuelas</p>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>
  );
}
