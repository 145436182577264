import React from "react";
import { Card } from "primereact/card";

function OralHealthCardsBody() {
  return (
    <div className="OralHealthCardsBody">
      <div className="card">
        <Card
          style={{
            maxWidth: "100%",
            textAlign: "center",
            padding: "30px",
          }}
        >
          <div
            className="accordion accordion-flush m-0 p-5"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  style={{ color: "#e75480" }}
                >
                  Estética y diseño de la risa
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    Nuestro presente y la risa son los elementos más importantes
                    que determinan nuestra apariencia. Cuando se trata de los
                    conceptos de “belleza” y “estética”, es la cara y la sonrisa
                    lo que viene a la mente en primer lugar tanto en mujeres
                    como en hombres. Los labios, los dientes, las encías, los
                    ojos, la nariz, las mejillas, el mentón y todos los demás
                    detalles de nuestra cara conforman nuestros sentimientos y
                    nos impresionan.
                  </p>
                  <p style={{ color: "gray" }}>
                    Las principales áreas de interés en las prácticas de
                    estética de la risa practicadas por nuestros especialistas
                    en sus campos son:
                  </p>
                  <p style={{ color: "gray" }}>
                    La posición, forma, color y estado estructural de los
                    dientes y la encía
                  </p>
                  <p style={{ color: "gray" }}>
                    Longitud de la cara a relación de ancho de cara La relación
                    de la distancia entre los labios y las cejas a la longitud
                    de la nariz.
                  </p>
                  <p style={{ color: "gray" }}>
                    La relación entre la longitud de la cara y la distancia
                    entre la mandíbula y las cejas Relación entre el ancho de la
                    boca y el ancho de la nariz.
                  </p>
                  <p style={{ color: "gray" }}>
                    La relación entre el ancho de la nariz y la distancia entre
                    las fosas nasales.
                  </p>
                  <p style={{ color: "gray" }}>
                    La relación de la distancia entre los globos oculares a la
                    distancia entre las cejas.
                  </p>
                  <p style={{ color: "gray" }}>
                    Distorsión y deformidades de órganos en la cara.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                  style={{ color: "#e75480" }}
                >
                  Tratamiento de implantes
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    Los implantes dentales son raíces dentales artificiales que
                    se colocan en la mandíbula para sostener las dentaduras
                    postizas. En presencia de dientes faltantes que alteran la
                    estética de los dientes y las sonrisas, generalmente se
                    prefiere aplicar implantes dentales. En aplicaciones de
                    implantación de dientes, las raíces artificiales de dientes
                    compatibles con tejidos corporales producidos a partir de un
                    material como el titanio se colocan en la mandíbula con un
                    pequeño procedimiento quirúrgico en un entorno estéril.
                    Aproximadamente 2-3 meses dentro de la mandíbula están
                    completamente adheridos al implante, la prótesis que se
                    colocará en la caja vendrá.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                  style={{ color: "#e75480" }}
                >
                  Tratamiento blanqueador de dientes
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    El tratamiento blanqueador es un tratamiento que preferimos
                    en pacientes jóvenes y adultos. Esta aplicación se puede
                    aplicar a bocas sanas, de lo contrario puede desarrollarse
                    sensibilidad dental. Por esta razón, el dentista puede
                    realizar procedimientos de blanqueamiento después de
                    descomposición, abrasiones y trastornos de las encías.
                  </p>
                  <p style={{ color: "gray" }}>
                    Se sabe que el blanqueamiento es particularmente exitoso en
                    personas con dientes de tono amarillo. La tasa de blancura
                    depende del tono de nuestros dientes antes de que se aplique
                    el proceso de blanqueamiento y varía de persona a persona.
                    Es por eso que debe hablar con su dentista por adelantado.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                  style={{ color: "#e75480" }}
                >
                  Ortodoncia
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    La ortodoncia es una especialidad de la odontología que
                    diagnostica y trata los dientes involuntarios y las
                    relaciones mandibulares incompatibles. Los dentistas que se
                    especializan en ortodoncia se llaman ortodoncistas.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                  style={{ color: "#e75480" }}
                >
                  Lámina de porcelana
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p style={{ color: "gray" }}>
                    Lámina de porcelana son hojas de porcelana (0.3, – 0.7 mm)
                    adheridas a las superficies frontales de los dientes. La
                    lámina, que imita el diente natural de la permeabilidad a la
                    luz, es la forma de tratamiento más estética que puede
                    corregir las formas y los colores de los dientes en muy poco
                    tiempo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default OralHealthCardsBody;
