import React from "react";
import { Card } from "primereact/card";
import CardMedia from "@mui/material/CardMedia";
import Img from "./img/7.png";
import { Divider } from "primereact/divider";

function DermatologyCardsImg() {
  return (
    <div className="DermatologyCardsImg" style={{paddingTop:'60px'}}>
      <div className="card text-center w-full" style={{
        padding: "0 30px",
        color: "#e75480",
      }}>
        <Divider align="center"><h2 className="p-3">Dermatología y estética médica</h2></Divider>
      </div>
      <img className="w-full"
        src={Img}
        alt="cardsimg"
      />
    </div>
  );
}

export default DermatologyCardsImg;
